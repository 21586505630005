import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  UUID: any;
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  acceptsMarketing: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  role: Role;
};

export type AccountInput = {
  acceptsMarketing: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type AddElementMessage = {
  __typename?: 'AddElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type AddSpreadMessage = {
  __typename?: 'AddSpreadMessage';
  destinationIndex: Scalars['Int'];
  id: Scalars['UUID'];
};

export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  /** Shopify id. */
  id: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['String'];
  zip: Scalars['String'];
};

export type AdminImageReorderInput = {
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
};

export type AdminImageUpdateInput = {
  id: Scalars['UUID'];
  status: ImageStatus;
};

export type Author = {
  __typename?: 'Author';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type Book = {
  __typename?: 'Book';
  id: Scalars['UUID'];
  isDeletable: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  memberRole?: Maybe<MemberRole>;
  memberStatus?: Maybe<MemberStatus>;
  modifiedAt: Scalars['Time'];
  owner?: Maybe<Account>;
  spine: Spine;
  spreads: Array<BookSpread>;
  title: Scalars['String'];
  viewableSpreads: Scalars['Boolean'];
};

export enum BookCover {
  Glossy = 'GLOSSY',
  Matte = 'MATTE',
}

export type BookElementContainer = {
  __typename?: 'BookElementContainer';
  createdAt: Scalars['Time'];
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  imageHeight: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  updatedAt: Scalars['Time'];
  width: Scalars['Float'];
};

export type BookElementFrame = {
  __typename?: 'BookElementFrame';
  createdAt: Scalars['Time'];
  frame: ElementImage;
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  imageHeight: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  mask: Mask;
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  updatedAt: Scalars['Time'];
  width: Scalars['Float'];
};

export type BookElementSticker = {
  __typename?: 'BookElementSticker';
  height: Scalars['Float'];
  id: Scalars['UUID'];
  image: ElementImage;
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  width: Scalars['Float'];
};

export type BookElementText = {
  __typename?: 'BookElementText';
  color: Scalars['String'];
  content: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontStyle: Scalars['String'];
  fontWeight: Scalars['Int'];
  height: Scalars['Float'];
  id: Scalars['UUID'];
  left: Scalars['Float'];
  placeholder: Scalars['String'];
  rotation: Scalars['Float'];
  textAlign: Scalars['String'];
  textDecoration: Scalars['String'];
  top: Scalars['Float'];
  type?: Maybe<ElementType>;
  width: Scalars['Float'];
};

export type BookImage = {
  __typename?: 'BookImage';
  aspectRatio?: Maybe<Scalars['Int']>;
  blobID: Scalars['UUID'];
  height?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type BookMember = {
  __typename?: 'BookMember';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  role: MemberRole;
  status: MemberStatus;
};

export type BookOrderInput = {
  amount: Scalars['Int'];
  cover: BookCover;
  isGift: Scalars['Boolean'];
  manualReviewRequested?: Scalars['Boolean'];
  paper: BookPaper;
  productionSpeed?: BookProductionSpeed;
  size: BookSize;
};

export enum BookPaper {
  LayFlat = 'LAY_FLAT',
  Regular = 'REGULAR',
}

export type BookPrice = {
  __typename?: 'BookPrice';
  cover: Scalars['String'];
  delivery: Scalars['String'];
  gift: Scalars['String'];
  manualReviewRequested: Scalars['String'];
  paper: Scalars['String'];
  productionSpeed: Scalars['String'];
  size: Scalars['String'];
  spreads: Scalars['String'];
  /** Including 9% tax. */
  total: Scalars['String'];
  totalPerBook: Scalars['String'];
};

export enum BookProductionSpeed {
  Express = 'EXPRESS',
  Standard = 'STANDARD',
}

export enum BookSize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
}

export type BookSpread = {
  __typename?: 'BookSpread';
  author?: Maybe<Author>;
  background?: Maybe<SpreadBackground>;
  elements: Array<GqlBookElement>;
  hasHistory: Scalars['Boolean'];
  id: Scalars['UUID'];
  isCollaborative: Scalars['Boolean'];
  isDuplicatable: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  thumbnailURL?: Maybe<Scalars['String']>;
};

export type ElementBlobInput = {
  height: Scalars['Float'];
  imageID: Scalars['UUID'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementContainerInput = {
  height: Scalars['Float'];
  imageHeight: Scalars['Float'];
  imageID?: InputMaybe<Scalars['UUID']>;
  imageLeft: Scalars['Float'];
  imageTop: Scalars['Float'];
  imageWidth: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementFrameInput = {
  frameID: Scalars['UUID'];
  height: Scalars['Float'];
  imageHeight?: InputMaybe<Scalars['Float']>;
  imageID?: InputMaybe<Scalars['UUID']>;
  imageLeft?: InputMaybe<Scalars['Float']>;
  imageTop?: InputMaybe<Scalars['Float']>;
  imageWidth?: InputMaybe<Scalars['Float']>;
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export type ElementImage = {
  __typename?: 'ElementImage';
  aspectRatio: Scalars['Float'];
  blobID: Scalars['UUID'];
  height: Scalars['Float'];
  url: Scalars['String'];
  width: Scalars['Float'];
};

export type ElementTextInput = {
  color: Scalars['String'];
  content: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontStyle: Scalars['String'];
  fontWeight: Scalars['Int'];
  height: Scalars['Float'];
  left: Scalars['Float'];
  placeholder: Scalars['String'];
  rotation: Scalars['Float'];
  textAlign: Scalars['String'];
  textDecoration: Scalars['String'];
  top: Scalars['Float'];
  width: Scalars['Float'];
};

export enum ElementType {
  Container = 'CONTAINER',
  Frame = 'FRAME',
  Sticker = 'STICKER',
  Text = 'TEXT',
}

export type GqlBookElement =
  | BookElementContainer
  | BookElementFrame
  | BookElementSticker
  | BookElementText;

export type Image = {
  __typename?: 'Image';
  blobID: Scalars['UUID'];
  originalHeight: Scalars['Int'];
  originalName: Scalars['String'];
  originalWidth: Scalars['Int'];
  status: ImageStatus;
  url: Scalars['String'];
  usageCount: Scalars['Int'];
};

export enum ImageStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum Mask {
  Circle = 'CIRCLE',
  Landscape = 'LANDSCAPE',
  Square = 'SQUARE',
}

export enum MemberRole {
  CoOwner = 'CO_OWNER',
  Owner = 'OWNER',
  Participant = 'PARTICIPANT',
}

export enum MemberStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  accountUpdate: Account;
  adminBackgroundReorder: Scalars['Boolean'];
  adminBackgroundUpdate: Image;
  adminBackgroundUpload: Image;
  adminFrameReorder: Scalars['Boolean'];
  adminFrameUpdate: Image;
  adminFrameUpload: Image;
  adminProductsReorder: Scalars['Boolean'];
  adminStickerReorder: Scalars['Boolean'];
  adminStickerUpdate: Image;
  adminStickerUpload: Image;
  bookContainerAdd: BookElementContainer;
  bookContainerUpdate: BookElementContainer;
  bookCreate: Scalars['UUID'];
  bookElementRemove: Scalars['Boolean'];
  bookElementReorder: Scalars['Boolean'];
  bookFrameAdd: BookElementFrame;
  bookFrameUpdate: BookElementFrame;
  bookInvitationTokenGenerate: Scalars['String'];
  bookInvitationTokenRemove: Scalars['Boolean'];
  bookJoin: Book;
  bookMarkAsComplete: Scalars['Boolean'];
  bookOrder: Scalars['String'];
  bookRemove: Scalars['Boolean'];
  bookShareTokenGenerate: Scalars['String'];
  bookShareTokenRemove: Scalars['Boolean'];
  bookSpineUpdate: Book;
  bookSpreadAdd: BookSpread;
  bookSpreadBackgroundUpdate: SpreadBackground;
  bookSpreadCreateCollaborative: Scalars['UUID'];
  bookSpreadDuplicate: Scalars['UUID'];
  bookSpreadRemove: Scalars['Boolean'];
  bookSpreadReorder: Scalars['Boolean'];
  bookSpreadToggleCollaborative: Scalars['Boolean'];
  bookSpreadUndo: Scalars['Boolean'];
  bookStickerAdd: BookElementSticker;
  bookStickerUpdate: BookElementSticker;
  bookTextAdd: BookElementText;
  bookTextUpdate: BookElementText;
  bookTitleUpdate: Book;
  bookToggleViewableSpreads: Scalars['Boolean'];
  bookUpdateMemberRole: Scalars['Boolean'];
  customImageRemove: Scalars['Boolean'];
  customImageUpload: Image;
  orderAssetsGenerate: Scalars['Boolean'];
  passwordChange: Tokens;
  passwordForgot: Scalars['Boolean'];
  passwordReset: Tokens;
  sendToPrinter: Scalars['Boolean'];
  signIn: Tokens;
  signOut: Scalars['Boolean'];
  signUp: Tokens;
};

export type MutationAccountUpdateArgs = {
  input: AccountInput;
};

export type MutationAdminBackgroundReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminBackgroundUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminBackgroundUploadArgs = {
  input: Scalars['Upload'];
};

export type MutationAdminFrameReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminFrameUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminFrameUploadArgs = {
  input: Scalars['Upload'];
  mask: Mask;
};

export type MutationAdminProductsReorderArgs = {
  input?: InputMaybe<ReorderInput>;
};

export type MutationAdminStickerReorderArgs = {
  input: AdminImageReorderInput;
};

export type MutationAdminStickerUpdateArgs = {
  input: AdminImageUpdateInput;
};

export type MutationAdminStickerUploadArgs = {
  input: Scalars['Upload'];
};

export type MutationBookContainerAddArgs = {
  input: ElementContainerInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookContainerUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementContainerInput;
};

export type MutationBookCreateArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookElementRemoveArgs = {
  elementID: Scalars['UUID'];
};

export type MutationBookElementReorderArgs = {
  input: ReorderInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookFrameAddArgs = {
  input: ElementFrameInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookFrameUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementFrameInput;
};

export type MutationBookInvitationTokenGenerateArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookInvitationTokenRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookJoinArgs = {
  invitationToken: Scalars['UUID'];
};

export type MutationBookMarkAsCompleteArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookOrderArgs = {
  bookID: Scalars['UUID'];
  input: BookOrderInput;
};

export type MutationBookRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookShareTokenGenerateArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookShareTokenRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpineUpdateArgs = {
  bookID: Scalars['UUID'];
  input: SpineInput;
};

export type MutationBookSpreadAddArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpreadBackgroundUpdateArgs = {
  input: SpreadBackgroundInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadCreateCollaborativeArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookSpreadDuplicateArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadRemoveArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadReorderArgs = {
  bookID: Scalars['UUID'];
  input: ReorderInput;
};

export type MutationBookSpreadToggleCollaborativeArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookSpreadUndoArgs = {
  spreadID: Scalars['UUID'];
};

export type MutationBookStickerAddArgs = {
  input: ElementBlobInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookStickerUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementBlobInput;
};

export type MutationBookTextAddArgs = {
  input: ElementTextInput;
  spreadID: Scalars['UUID'];
};

export type MutationBookTextUpdateArgs = {
  elementID: Scalars['UUID'];
  input: ElementTextInput;
};

export type MutationBookTitleUpdateArgs = {
  bookID: Scalars['UUID'];
  input: Scalars['String'];
};

export type MutationBookToggleViewableSpreadsArgs = {
  bookID: Scalars['UUID'];
};

export type MutationBookUpdateMemberRoleArgs = {
  memberID: Scalars['UUID'];
  role: MemberRole;
};

export type MutationCustomImageRemoveArgs = {
  imageID: Scalars['UUID'];
};

export type MutationCustomImageUploadArgs = {
  bookID: Scalars['UUID'];
  input: Scalars['Upload'];
};

export type MutationOrderAssetsGenerateArgs = {
  orderID: Scalars['UUID'];
};

export type MutationPasswordChangeArgs = {
  password: Scalars['String'];
};

export type MutationPasswordForgotArgs = {
  email: Scalars['String'];
};

export type MutationPasswordResetArgs = {
  password: Scalars['String'];
  resetURL: Scalars['String'];
};

export type MutationSendToPrinterArgs = {
  orderID: Scalars['UUID'];
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type Order = {
  __typename?: 'Order';
  assets: OrderAssets;
  billingAddress: OrderAddress;
  bookID: Scalars['UUID'];
  cover: BookCover;
  createdAt: Scalars['Time'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  orderNumber: Scalars['Int'];
  pageCount: Scalars['Int'];
  paper: BookPaper;
  priceShipping: Scalars['String'];
  priceTotal: Scalars['String'];
  productImage: Scalars['String'];
  quantity: Scalars['Int'];
  shippingAddress: OrderAddress;
  size: BookSize;
  status: OrderStatus;
  title: Scalars['String'];
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  zip: Scalars['String'];
};

export type OrderAssets = {
  __typename?: 'OrderAssets';
  body: Scalars['String'];
  cover: Scalars['String'];
};

export enum OrderStatus {
  AssetsGenerated = 'ASSETS_GENERATED',
  Printing = 'PRINTING',
  Processing = 'PROCESSING',
}

export type PageBackground = {
  __typename?: 'PageBackground';
  color?: Maybe<Scalars['String']>;
  image?: Maybe<BookImage>;
};

export type Product = {
  __typename?: 'Product';
  bookID: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  exampleBookLarge?: Maybe<Scalars['String']>;
  exampleBookLargeMobile?: Maybe<Scalars['String']>;
  exampleBookMedium?: Maybe<Scalars['String']>;
  filters: Array<ProductFilter>;
  heroImage?: Maybe<Scalars['String']>;
  hideCTA: Scalars['Boolean'];
  id: Scalars['UUID'];
  image?: Maybe<Scalars['String']>;
  images: Array<Scalars['String']>;
  introImage?: Maybe<Scalars['String']>;
  introText?: Maybe<Scalars['String']>;
  introTitle?: Maybe<Scalars['String']>;
  promoColor?: Maybe<Scalars['String']>;
  promoImage?: Maybe<Scalars['String']>;
  promoTitle?: Maybe<Scalars['String']>;
  seo: Seo;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  category: Scalars['String'];
  id: Scalars['UUID'];
  value: Scalars['String'];
};

export type ProductFilterCategory = {
  __typename?: 'ProductFilterCategory';
  filters: Array<ProductFilter>;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  adminOrders: Array<Order>;
  adminProducts: Array<Product>;
  backgrounds: Array<Image>;
  book: Book;
  bookInvitationToken?: Maybe<Scalars['String']>;
  bookMembers: Array<BookMember>;
  bookShareToken?: Maybe<Scalars['String']>;
  books: Array<Book>;
  calculatePrice: BookPrice;
  customImages: Array<Image>;
  element: GqlBookElement;
  featuredProducts: Array<Product>;
  frames: Array<Image>;
  order: Order;
  orders: Array<Order>;
  product: Product;
  productFilterCategories: Array<ProductFilterCategory>;
  products: Array<Product>;
  sharedBook: SharedBook;
  spread: BookSpread;
  stickers: Array<Image>;
};

export type QueryBookArgs = {
  bookID: Scalars['UUID'];
  invitationToken?: InputMaybe<Scalars['String']>;
};

export type QueryBookInvitationTokenArgs = {
  bookID: Scalars['UUID'];
};

export type QueryBookMembersArgs = {
  bookID: Scalars['UUID'];
};

export type QueryBookShareTokenArgs = {
  bookID: Scalars['UUID'];
};

export type QueryCalculatePriceArgs = {
  bookID: Scalars['UUID'];
  input: BookOrderInput;
};

export type QueryCustomImagesArgs = {
  bookID: Scalars['UUID'];
};

export type QueryElementArgs = {
  bookID: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type QueryOrderArgs = {
  orderID: Scalars['UUID'];
};

export type QueryProductArgs = {
  slug: Scalars['String'];
};

export type QueryProductsArgs = {
  filters?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QuerySharedBookArgs = {
  bookID: Scalars['UUID'];
  shareToken: Scalars['String'];
};

export type QuerySpreadArgs = {
  spreadID: Scalars['UUID'];
};

export type RemoveElementMessage = {
  __typename?: 'RemoveElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type RemoveSpreadMessage = {
  __typename?: 'RemoveSpreadMessage';
  id: Scalars['UUID'];
};

export type ReorderElementMessage = {
  __typename?: 'ReorderElementMessage';
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
  spreadID: Scalars['UUID'];
};

export type ReorderInput = {
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
};

export type ReorderSpreadMessage = {
  __typename?: 'ReorderSpreadMessage';
  destinationIndex: Scalars['Int'];
  sourceIndex: Scalars['Int'];
  spreadID: Scalars['UUID'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER',
}

export type Seo = {
  __typename?: 'SEO';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type SharedBook = {
  __typename?: 'SharedBook';
  id: Scalars['UUID'];
  spreads: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpInput = {
  acceptsMarketing?: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type Spine = {
  __typename?: 'Spine';
  backgroundColor: Scalars['String'];
  text: Scalars['String'];
  textColor: Scalars['String'];
};

export type SpineInput = {
  backgroundColor: Scalars['String'];
  text: Scalars['String'];
  textColor: Scalars['String'];
};

export type SpreadBackground = {
  __typename?: 'SpreadBackground';
  left?: Maybe<PageBackground>;
  right?: Maybe<PageBackground>;
};

export type SpreadBackgroundInput = {
  leftColor?: InputMaybe<Scalars['String']>;
  leftImage?: InputMaybe<Scalars['UUID']>;
  rightColor?: InputMaybe<Scalars['String']>;
  rightImage?: InputMaybe<Scalars['UUID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  addElement: AddElementMessage;
  removeElement: RemoveElementMessage;
  reorderElement: ReorderElementMessage;
  spineUpdate: UpdateSpineMessage;
  spreadAdd: AddSpreadMessage;
  spreadBackgroundUpdate: UpdateSpreadBackgroundMessage;
  spreadRemove: RemoveSpreadMessage;
  spreadReorder: ReorderSpreadMessage;
  spreadThumbnailUpdate: UpdateSpreadThumbnailMessage;
  undoElement: UndoElementMessage;
  updateElement: UpdateElementMessage;
};

export type SubscriptionAddElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionRemoveElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionReorderElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpineUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadAddArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadBackgroundUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadRemoveArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadReorderArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionSpreadThumbnailUpdateArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionUndoElementArgs = {
  bookID: Scalars['UUID'];
};

export type SubscriptionUpdateElementArgs = {
  bookID: Scalars['UUID'];
};

export type Tokens = {
  __typename?: 'Tokens';
  accessToken: Scalars['String'];
  expiresAt: Scalars['Int'];
};

export type UndoElementMessage = {
  __typename?: 'UndoElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type UpdateElementMessage = {
  __typename?: 'UpdateElementMessage';
  id: Scalars['UUID'];
  spreadID: Scalars['UUID'];
};

export type UpdateSpineMessage = {
  __typename?: 'UpdateSpineMessage';
  bookID: Scalars['UUID'];
};

export type UpdateSpreadBackgroundMessage = {
  __typename?: 'UpdateSpreadBackgroundMessage';
  spreadID: Scalars['UUID'];
};

export type UpdateSpreadThumbnailMessage = {
  __typename?: 'UpdateSpreadThumbnailMessage';
  spreadID: Scalars['UUID'];
  thumbnailURL: Scalars['String'];
};

export type AdminStickerUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;

export type AdminStickerUploadMutation = {
  __typename?: 'Mutation';
  adminStickerUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminStickerReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminStickerReorderMutation = {
  __typename?: 'Mutation';
  adminStickerReorder: boolean;
};

export type AdminFrameUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
  mask: Mask;
}>;

export type AdminFrameUploadMutation = {
  __typename?: 'Mutation';
  adminFrameUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminFrameReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminFrameReorderMutation = {
  __typename?: 'Mutation';
  adminFrameReorder: boolean;
};

export type AdminBackgroundUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;

export type AdminBackgroundUploadMutation = {
  __typename?: 'Mutation';
  adminBackgroundUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type AdminBackgroundReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminBackgroundReorderMutation = {
  __typename?: 'Mutation';
  adminBackgroundReorder: boolean;
};

export type AdminProductsReorderMutationVariables = Exact<{
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type AdminProductsReorderMutation = {
  __typename?: 'Mutation';
  adminProductsReorder: boolean;
};

export type AdminOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminOrdersQuery = {
  __typename?: 'Query';
  adminOrders: Array<{
    __typename?: 'Order';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: any;
    status: OrderStatus;
    bookID: any;
    assets: { __typename?: 'OrderAssets'; cover: string; body: string };
  }>;
};

export type OrderAssetsGenerateMutationVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type OrderAssetsGenerateMutation = {
  __typename?: 'Mutation';
  orderAssetsGenerate: boolean;
};

export type SendToPrinterMutationVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type SendToPrinterMutation = {
  __typename?: 'Mutation';
  sendToPrinter: boolean;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: { __typename?: 'Tokens'; accessToken: string };
};

export type SignInMutationVariables = Exact<{
  input: SignInInput;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'Tokens'; accessToken: string };
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = { __typename?: 'Mutation'; signOut: boolean };

export type GetAccountInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountInfoQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
  };
};

export type PasswordForgotnMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type PasswordForgotnMutation = {
  __typename?: 'Mutation';
  passwordForgot: boolean;
};

export type PasswordResetMutationVariables = Exact<{
  resetUrl: Scalars['String'];
  password: Scalars['String'];
}>;

export type PasswordResetMutation = {
  __typename?: 'Mutation';
  passwordReset: { __typename?: 'Tokens'; accessToken: string };
};

export type BookCreateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookCreateMutation = { __typename?: 'Mutation'; bookCreate: any };

export type GetPrintSpreadQueryVariables = Exact<{
  spreadID: Scalars['UUID'];
}>;

export type GetPrintSpreadQuery = {
  __typename?: 'Query';
  spread: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type GetPrintSpineQueryVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type GetPrintSpineQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type CalculatePriceQueryVariables = Exact<{
  bookID: Scalars['UUID'];
  input: BookOrderInput;
}>;

export type CalculatePriceQuery = {
  __typename?: 'Query';
  calculatePrice: {
    __typename?: 'BookPrice';
    size: string;
    paper: string;
    spreads: string;
    cover: string;
    gift: string;
    delivery: string;
    productionSpeed: string;
    manualReviewRequested: string;
    totalPerBook: string;
    total: string;
  };
};

export type BookOrderMutationVariables = Exact<{
  bookID: Scalars['UUID'];
  input: BookOrderInput;
}>;

export type BookOrderMutation = { __typename?: 'Mutation'; bookOrder: string };

export type BookRemoveMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookRemoveMutation = {
  __typename?: 'Mutation';
  bookRemove: boolean;
};

export type BookTitleUpdateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
  input: Scalars['String'];
}>;

export type BookTitleUpdateMutation = {
  __typename?: 'Mutation';
  bookTitleUpdate: { __typename?: 'Book'; id: any; title: string };
};

export type BookUpdateMemberRoleMutationVariables = Exact<{
  memberID: Scalars['UUID'];
  role: MemberRole;
}>;

export type BookUpdateMemberRoleMutation = {
  __typename?: 'Mutation';
  bookUpdateMemberRole: boolean;
};

export type GetSharedBookQueryVariables = Exact<{
  bookId: Scalars['UUID'];
  shareToken: Scalars['String'];
}>;

export type GetSharedBookQuery = {
  __typename?: 'Query';
  sharedBook: {
    __typename?: 'SharedBook';
    id: any;
    title: string;
    spreads: Array<string>;
  };
};

export type GetShareTokenQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetShareTokenQuery = {
  __typename?: 'Query';
  bookShareToken?: string | undefined;
};

export type GenerateShareTokenMutationVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GenerateShareTokenMutation = {
  __typename?: 'Mutation';
  bookShareTokenGenerate: string;
};

export type RemoveShareTokenMutationVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type RemoveShareTokenMutation = {
  __typename?: 'Mutation';
  bookShareTokenRemove: boolean;
};

export type GetBookByIdQueryVariables = Exact<{
  bookId: Scalars['UUID'];
  invitationToken?: InputMaybe<Scalars['String']>;
}>;

export type GetBookByIdQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    id: any;
    title: string;
    isTemplate: boolean;
    isDeletable: boolean;
    memberRole?: MemberRole | undefined;
    memberStatus?: MemberStatus | undefined;
    viewableSpreads: boolean;
    modifiedAt: any;
    owner?:
      | { __typename?: 'Account'; email: string; firstName: string; id: any }
      | undefined;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isTemplate: boolean;
      isCollaborative: boolean;
      isDuplicatable: boolean;
      isEditable: boolean;
      hasHistory: boolean;
      thumbnailURL?: string | undefined;
      author?:
        | {
            __typename?: 'Author';
            email: string;
            firstName: string;
            lastName: string;
          }
        | undefined;
      elements: Array<
        | {
            __typename?: 'BookElementContainer';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementFrame';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            mask: Mask;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
            frame: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementSticker';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementText';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            content: string;
            placeholder: string;
            color: string;
            fontFamily: string;
            fontSize: number;
            fontWeight: number;
            fontStyle: string;
            textDecoration: string;
            textAlign: string;
          }
      >;
      background?:
        | {
            __typename?: 'SpreadBackground';
            left?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
            right?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
          }
        | undefined;
    }>;
  };
};

export type GetSpineQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetSpineQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    id: any;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type GetSpreadByIdQueryVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type GetSpreadByIdQuery = {
  __typename?: 'Query';
  spread: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type GetCustomImagesQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetCustomImagesQuery = {
  __typename?: 'Query';
  customImages: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
};

export type GetMediaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaQuery = {
  __typename?: 'Query';
  stickers: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
  frames: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
  backgrounds: Array<{
    __typename?: 'Image';
    blobID: any;
    url: string;
    originalWidth: number;
    originalHeight: number;
    usageCount: number;
  }>;
};

export type SpineUpdateMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  text: Scalars['String'];
  textColor: Scalars['String'];
  backgroundColor: Scalars['String'];
}>;

export type SpineUpdateMutation = {
  __typename?: 'Mutation';
  bookSpineUpdate: {
    __typename?: 'Book';
    id: any;
    spine: {
      __typename?: 'Spine';
      text: string;
      textColor: string;
      backgroundColor: string;
    };
  };
};

export type SpreadAddMutationVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type SpreadAddMutation = {
  __typename?: 'Mutation';
  bookSpreadAdd: {
    __typename?: 'BookSpread';
    id: any;
    isTemplate: boolean;
    isCollaborative: boolean;
    isDuplicatable: boolean;
    isEditable: boolean;
    hasHistory: boolean;
    thumbnailURL?: string | undefined;
    author?:
      | {
          __typename?: 'Author';
          email: string;
          firstName: string;
          lastName: string;
        }
      | undefined;
    elements: Array<
      | {
          __typename?: 'BookElementContainer';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementFrame';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          top: number;
          left: number;
          rotation: number;
          mask: Mask;
          round: boolean;
          imageWidth: number;
          imageHeight: number;
          imageTop: number;
          imageLeft: number;
          createdAt: any;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
          frame: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementSticker';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          image: {
            __typename?: 'ElementImage';
            blobID: any;
            url: string;
            aspectRatio: number;
            height: number;
            width: number;
          };
        }
      | {
          __typename?: 'BookElementText';
          id: any;
          type?: ElementType | undefined;
          width: number;
          height: number;
          rotation: number;
          top: number;
          left: number;
          content: string;
          placeholder: string;
          color: string;
          fontFamily: string;
          fontSize: number;
          fontWeight: number;
          fontStyle: string;
          textDecoration: string;
          textAlign: string;
        }
    >;
    background?:
      | {
          __typename?: 'SpreadBackground';
          left?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
          right?:
            | {
                __typename?: 'PageBackground';
                color?: string | undefined;
                image?:
                  | { __typename?: 'BookImage'; url: string; blobID: any }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
  };
};

export type SpreadReorderMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type SpreadReorderMutation = {
  __typename?: 'Mutation';
  bookSpreadReorder: boolean;
};

export type SpreadRemoveMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type SpreadRemoveMutation = {
  __typename?: 'Mutation';
  bookSpreadRemove: boolean;
};

export type SpreadBackgroundUpdateMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  leftImageId?: InputMaybe<Scalars['UUID']>;
  rightImageId?: InputMaybe<Scalars['UUID']>;
  leftColor?: InputMaybe<Scalars['String']>;
  rightColor?: InputMaybe<Scalars['String']>;
}>;

export type SpreadBackgroundUpdateMutation = {
  __typename?: 'Mutation';
  bookSpreadBackgroundUpdate: { __typename: 'SpreadBackground' };
};

export type ImageUploadMutationVariables = Exact<{
  bookId: Scalars['UUID'];
  input: Scalars['Upload'];
}>;

export type ImageUploadMutation = {
  __typename?: 'Mutation';
  customImageUpload: { __typename?: 'Image'; blobID: any; url: string };
};

export type ImageRemoveMutationVariables = Exact<{
  imageId: Scalars['UUID'];
}>;

export type ImageRemoveMutation = {
  __typename?: 'Mutation';
  customImageRemove: boolean;
};

export type ContainerAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  rotation?: InputMaybe<Scalars['Float']>;
  round?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop?: Scalars['Float'];
  imageLeft?: Scalars['Float'];
  imageWidth?: Scalars['Float'];
  imageHeight?: Scalars['Float'];
}>;

export type ContainerAddMutation = {
  __typename?: 'Mutation';
  bookContainerAdd: { __typename?: 'BookElementContainer'; id: any };
};

export type FrameAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  width: Scalars['Float'];
  height: Scalars['Float'];
  rotation?: InputMaybe<Scalars['Float']>;
  frameId: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop?: Scalars['Float'];
  imageLeft?: Scalars['Float'];
  imageWidth?: Scalars['Float'];
  imageHeight?: Scalars['Float'];
}>;

export type FrameAddMutation = {
  __typename?: 'Mutation';
  bookFrameAdd: { __typename?: 'BookElementFrame'; id: any };
};

export type StickerAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation?: InputMaybe<Scalars['Float']>;
  imageId: Scalars['UUID'];
}>;

export type StickerAddMutation = {
  __typename?: 'Mutation';
  bookStickerAdd: { __typename?: 'BookElementSticker'; id: any };
};

export type TextAddMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  width?: Scalars['Float'];
  height?: Scalars['Float'];
  top?: InputMaybe<Scalars['Float']>;
  left?: InputMaybe<Scalars['Float']>;
  rotation?: InputMaybe<Scalars['Float']>;
  content: Scalars['String'];
  placeholder: Scalars['String'];
  fontFamily?: InputMaybe<Scalars['String']>;
  fontSize?: InputMaybe<Scalars['Float']>;
  fontWeight?: InputMaybe<Scalars['Int']>;
  fontStyle?: InputMaybe<Scalars['String']>;
  textDecoration?: InputMaybe<Scalars['String']>;
  textAlign?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
}>;

export type TextAddMutation = {
  __typename?: 'Mutation';
  bookTextAdd: { __typename?: 'BookElementText'; id: any };
};

export type ContainerUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  round: Scalars['Boolean'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageWidth: Scalars['Float'];
  imageHeight: Scalars['Float'];
}>;

export type ContainerUpdateMutation = {
  __typename?: 'Mutation';
  bookContainerUpdate: {
    __typename?: 'BookElementContainer';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    round: boolean;
    imageTop: number;
    imageLeft: number;
    imageWidth: number;
    imageHeight: number;
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type FrameUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  rotation: Scalars['Float'];
  frameId: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  imageTop: Scalars['Float'];
  imageLeft: Scalars['Float'];
  imageWidth: Scalars['Float'];
  imageHeight: Scalars['Float'];
}>;

export type FrameUpdateMutation = {
  __typename?: 'Mutation';
  bookFrameUpdate: {
    __typename?: 'BookElementFrame';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    round: boolean;
    imageTop: number;
    imageLeft: number;
    imageWidth: number;
    imageHeight: number;
    frame: { __typename?: 'ElementImage'; blobID: any; url: string };
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type StickerUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  imageId: Scalars['UUID'];
}>;

export type StickerUpdateMutation = {
  __typename?: 'Mutation';
  bookStickerUpdate: {
    __typename?: 'BookElementSticker';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    image: {
      __typename?: 'ElementImage';
      blobID: any;
      url: string;
      aspectRatio: number;
    };
  };
};

export type TextUpdateMutationVariables = Exact<{
  elementId: Scalars['UUID'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  top: Scalars['Float'];
  left: Scalars['Float'];
  rotation: Scalars['Float'];
  content: Scalars['String'];
  placeholder: Scalars['String'];
  fontFamily: Scalars['String'];
  fontSize: Scalars['Float'];
  fontWeight: Scalars['Int'];
  fontStyle: Scalars['String'];
  textDecoration: Scalars['String'];
  textAlign: Scalars['String'];
  color: Scalars['String'];
}>;

export type TextUpdateMutation = {
  __typename?: 'Mutation';
  bookTextUpdate: {
    __typename?: 'BookElementText';
    id: any;
    width: number;
    height: number;
    top: number;
    left: number;
    rotation: number;
    content: string;
    placeholder: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: number;
    fontStyle: string;
    textDecoration: string;
    textAlign: string;
    color: string;
  };
};

export type ElementRemoveMutationVariables = Exact<{
  elementId: Scalars['UUID'];
}>;

export type ElementRemoveMutation = {
  __typename?: 'Mutation';
  bookElementRemove: boolean;
};

export type ElementReorderMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
  sourceIndex: Scalars['Int'];
  destinationIndex: Scalars['Int'];
}>;

export type ElementReorderMutation = {
  __typename?: 'Mutation';
  bookElementReorder: boolean;
};

export type BookSpreadToggleCollaborativeMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type BookSpreadToggleCollaborativeMutation = {
  __typename?: 'Mutation';
  bookSpreadToggleCollaborative: boolean;
};

export type BookInvitationTokenGenerateMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookInvitationTokenGenerateMutation = {
  __typename?: 'Mutation';
  bookInvitationTokenGenerate: string;
};

export type GetBookInvitationTokenQueryVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type GetBookInvitationTokenQuery = {
  __typename?: 'Query';
  bookInvitationToken?: string | undefined;
};

export type BookSpreadDuplicateMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type BookSpreadDuplicateMutation = {
  __typename?: 'Mutation';
  bookSpreadDuplicate: any;
};

export type BookJoinMutationVariables = Exact<{
  invitationToken: Scalars['UUID'];
}>;

export type BookJoinMutation = {
  __typename?: 'Mutation';
  bookJoin: {
    __typename?: 'Book';
    id: any;
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isTemplate: boolean;
      isCollaborative: boolean;
      isDuplicatable: boolean;
      isEditable: boolean;
      hasHistory: boolean;
      thumbnailURL?: string | undefined;
      author?:
        | {
            __typename?: 'Author';
            email: string;
            firstName: string;
            lastName: string;
          }
        | undefined;
      elements: Array<
        | {
            __typename?: 'BookElementContainer';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementFrame';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            top: number;
            left: number;
            rotation: number;
            mask: Mask;
            round: boolean;
            imageWidth: number;
            imageHeight: number;
            imageTop: number;
            imageLeft: number;
            createdAt: any;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
            frame: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementSticker';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            image: {
              __typename?: 'ElementImage';
              blobID: any;
              url: string;
              aspectRatio: number;
              height: number;
              width: number;
            };
          }
        | {
            __typename?: 'BookElementText';
            id: any;
            type?: ElementType | undefined;
            width: number;
            height: number;
            rotation: number;
            top: number;
            left: number;
            content: string;
            placeholder: string;
            color: string;
            fontFamily: string;
            fontSize: number;
            fontWeight: number;
            fontStyle: string;
            textDecoration: string;
            textAlign: string;
          }
      >;
      background?:
        | {
            __typename?: 'SpreadBackground';
            left?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
            right?:
              | {
                  __typename?: 'PageBackground';
                  color?: string | undefined;
                  image?:
                    | { __typename?: 'BookImage'; url: string; blobID: any }
                    | undefined;
                }
              | undefined;
          }
        | undefined;
    }>;
  };
};

export type BookSpreadCreateCollaborativeMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookSpreadCreateCollaborativeMutation = {
  __typename?: 'Mutation';
  bookSpreadCreateCollaborative: any;
};

export type BookMarkAsCompleteMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookMarkAsCompleteMutation = {
  __typename?: 'Mutation';
  bookMarkAsComplete: boolean;
};

export type UndoSpreadMutationVariables = Exact<{
  spreadId: Scalars['UUID'];
}>;

export type UndoSpreadMutation = {
  __typename?: 'Mutation';
  bookSpreadUndo: boolean;
};

export type BookToggleViewableSpreadsMutationVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type BookToggleViewableSpreadsMutation = {
  __typename?: 'Mutation';
  bookToggleViewableSpreads: boolean;
};

export type SpineFragment = {
  __typename?: 'Spine';
  text: string;
  textColor: string;
  backgroundColor: string;
};

export type BlobImageFragment = {
  __typename?: 'Image';
  blobID: any;
  url: string;
  originalWidth: number;
  originalHeight: number;
  usageCount: number;
};

export type ElementImageFragment = {
  __typename?: 'ElementImage';
  blobID: any;
  url: string;
  aspectRatio: number;
  height: number;
  width: number;
};

export type SpreadFragment = {
  __typename?: 'BookSpread';
  id: any;
  isTemplate: boolean;
  isCollaborative: boolean;
  isDuplicatable: boolean;
  isEditable: boolean;
  hasHistory: boolean;
  thumbnailURL?: string | undefined;
  author?:
    | {
        __typename?: 'Author';
        email: string;
        firstName: string;
        lastName: string;
      }
    | undefined;
  elements: Array<
    | {
        __typename?: 'BookElementContainer';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        top: number;
        left: number;
        rotation: number;
        round: boolean;
        imageWidth: number;
        imageHeight: number;
        imageTop: number;
        imageLeft: number;
        createdAt: any;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementFrame';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        top: number;
        left: number;
        rotation: number;
        mask: Mask;
        round: boolean;
        imageWidth: number;
        imageHeight: number;
        imageTop: number;
        imageLeft: number;
        createdAt: any;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
        frame: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementSticker';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        rotation: number;
        top: number;
        left: number;
        image: {
          __typename?: 'ElementImage';
          blobID: any;
          url: string;
          aspectRatio: number;
          height: number;
          width: number;
        };
      }
    | {
        __typename?: 'BookElementText';
        id: any;
        type?: ElementType | undefined;
        width: number;
        height: number;
        rotation: number;
        top: number;
        left: number;
        content: string;
        placeholder: string;
        color: string;
        fontFamily: string;
        fontSize: number;
        fontWeight: number;
        fontStyle: string;
        textDecoration: string;
        textAlign: string;
      }
  >;
  background?:
    | {
        __typename?: 'SpreadBackground';
        left?:
          | {
              __typename?: 'PageBackground';
              color?: string | undefined;
              image?:
                | { __typename?: 'BookImage'; url: string; blobID: any }
                | undefined;
            }
          | undefined;
        right?:
          | {
              __typename?: 'PageBackground';
              color?: string | undefined;
              image?:
                | { __typename?: 'BookImage'; url: string; blobID: any }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type GetProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetProductBySlugQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    description?: string | undefined;
    image?: string | undefined;
    introTitle?: string | undefined;
    introImage?: string | undefined;
    heroImage?: string | undefined;
    exampleBookLargeMobile?: string | undefined;
    exampleBookLarge?: string | undefined;
    exampleBookMedium?: string | undefined;
    hideCTA: boolean;
    images: Array<string>;
    seo: { __typename?: 'SEO'; title: string; description: string };
  };
};

export type GetCollaborativeSpreadsQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetCollaborativeSpreadsQuery = {
  __typename?: 'Query';
  book: {
    __typename?: 'Book';
    spreads: Array<{
      __typename?: 'BookSpread';
      id: any;
      isCollaborative: boolean;
    }>;
  };
};

export type GetProductsFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsFiltersQuery = {
  __typename?: 'Query';
  productFilterCategories: Array<{
    __typename?: 'ProductFilterCategory';
    name: string;
    filters: Array<{ __typename?: 'ProductFilter'; id: any; value: string }>;
  }>;
};

export type GetAllProductsQueryVariables = Exact<{
  filters?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;

export type GetAllProductsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    image?: string | undefined;
    promoTitle?: string | undefined;
    promoColor?: string | undefined;
    promoImage?: string | undefined;
    introText?: string | undefined;
    filters: Array<{
      __typename?: 'ProductFilter';
      id: any;
      value: string;
      category: string;
    }>;
  }>;
};

export type GetFeaturedQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeaturedQuery = {
  __typename?: 'Query';
  featuredProducts: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    slug: string;
    title: string;
    promoTitle?: string | undefined;
    promoColor?: string | undefined;
    promoImage?: string | undefined;
  }>;
};

export type GetBooksPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetBooksPageQuery = {
  __typename?: 'Query';
  books: Array<{
    __typename?: 'Book';
    id: any;
    title: string;
    modifiedAt: any;
    owner?: { __typename?: 'Account'; id: any } | undefined;
  }>;
};

export type GetProductsPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsPageQuery = {
  __typename?: 'Query';
  adminProducts: Array<{
    __typename?: 'Product';
    id: any;
    bookID: any;
    title: string;
  }>;
};

export type GetAllStickersPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllStickersPageQuery = {
  __typename?: 'Query';
  stickers: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAllFramesPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFramesPageQuery = {
  __typename?: 'Query';
  frames: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAllBackgroundsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllBackgroundsPageQuery = {
  __typename?: 'Query';
  backgrounds: Array<{
    __typename?: 'Image';
    url: string;
    status: ImageStatus;
    blobID: any;
    originalName: string;
  }>;
};

export type GetAccountPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
  };
  orders: Array<{
    __typename?: 'Order';
    productImage: string;
    title: string;
    size: BookSize;
    status: OrderStatus;
  }>;
};

export type GetAccountDetailsPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountDetailsPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type GetAccountPersonalPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountPersonalPageQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
    acceptsMarketing: boolean;
  };
};

export type AccountUpdateMutationVariables = Exact<{
  input: AccountInput;
}>;

export type AccountUpdateMutation = {
  __typename?: 'Mutation';
  accountUpdate: {
    __typename?: 'Account';
    firstName: string;
    lastName: string;
    email: string;
    acceptsMarketing: boolean;
  };
};

export type GetAccountAddressesPageQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAccountAddressesPageQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; firstName: string; lastName: string };
};

export type GetOrdersPageQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersPageQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    orderNumber: number;
    title: string;
    id: any;
    productImage: string;
    status: OrderStatus;
    createdAt: any;
    bookID: any;
  }>;
};

export type GetOrderPageQueryVariables = Exact<{
  orderID: Scalars['UUID'];
}>;

export type GetOrderPageQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    paper: BookPaper;
    pageCount: number;
    productImage: string;
    title: string;
    orderNumber: number;
    priceTotal: string;
    firstName: string;
    lastName: string;
    createdAt: any;
    status: OrderStatus;
    size: BookSize;
    cover: BookCover;
    quantity: number;
    priceShipping: string;
    shippingAddress: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      zip: string;
      country: string;
    };
    billingAddress: {
      __typename?: 'OrderAddress';
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      zip: string;
      country: string;
    };
  };
};

export type PasswordChangeMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type PasswordChangeMutation = {
  __typename?: 'Mutation';
  passwordChange: { __typename?: 'Tokens'; accessToken: string };
};

export type GetBookMembersQueryVariables = Exact<{
  bookId: Scalars['UUID'];
}>;

export type GetBookMembersQuery = {
  __typename?: 'Query';
  bookMembers: Array<{
    __typename?: 'BookMember';
    id: any;
    firstName: string;
    lastName: string;
    email: string;
    role: MemberRole;
    status: MemberStatus;
  }>;
};

export type OnSpreadReorderSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadReorderSubscription = {
  __typename?: 'Subscription';
  spreadReorder: {
    __typename?: 'ReorderSpreadMessage';
    spreadID: any;
    sourceIndex: number;
    destinationIndex: number;
  };
};

export type OnSpreadRemoveSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadRemoveSubscription = {
  __typename?: 'Subscription';
  spreadRemove: { __typename?: 'RemoveSpreadMessage'; id: any };
};

export type OnElementUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementUpdateSubscription = {
  __typename?: 'Subscription';
  updateElement: {
    __typename?: 'UpdateElementMessage';
    id: any;
    spreadID: any;
  };
};

export type OnSpreadAddSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadAddSubscription = {
  __typename?: 'Subscription';
  spreadAdd: {
    __typename?: 'AddSpreadMessage';
    id: any;
    destinationIndex: number;
  };
};

export type OnElementAddSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementAddSubscription = {
  __typename?: 'Subscription';
  addElement: { __typename?: 'AddElementMessage'; id: any; spreadID: any };
};

export type OnElementReorderSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementReorderSubscription = {
  __typename?: 'Subscription';
  reorderElement: {
    __typename?: 'ReorderElementMessage';
    spreadID: any;
    sourceIndex: number;
    destinationIndex: number;
  };
};

export type OnElementRemoveSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementRemoveSubscription = {
  __typename?: 'Subscription';
  removeElement: {
    __typename?: 'RemoveElementMessage';
    id: any;
    spreadID: any;
  };
};

export type OnElementUndoSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnElementUndoSubscription = {
  __typename?: 'Subscription';
  undoElement: { __typename?: 'UndoElementMessage'; id: any; spreadID: any };
};

export type OnSpreadBackgroundUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadBackgroundUpdateSubscription = {
  __typename?: 'Subscription';
  spreadBackgroundUpdate: {
    __typename?: 'UpdateSpreadBackgroundMessage';
    spreadID: any;
  };
};

export type OnSpreadThumbnailUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpreadThumbnailUpdateSubscription = {
  __typename?: 'Subscription';
  spreadThumbnailUpdate: {
    __typename?: 'UpdateSpreadThumbnailMessage';
    spreadID: any;
    thumbnailURL: string;
  };
};

export type OnSpineUpdateSubscriptionVariables = Exact<{
  bookID: Scalars['UUID'];
}>;

export type OnSpineUpdateSubscription = {
  __typename?: 'Subscription';
  spineUpdate: { __typename?: 'UpdateSpineMessage'; bookID: any };
};

export const SpineFragmentDoc = gql`
  fragment Spine on Spine {
    text
    textColor
    backgroundColor
  }
`;
export const BlobImageFragmentDoc = gql`
  fragment BlobImage on Image {
    blobID
    url
    originalWidth
    originalHeight
    usageCount
  }
`;
export const ElementImageFragmentDoc = gql`
  fragment ElementImage on ElementImage {
    blobID
    url
    aspectRatio
    height
    width
  }
`;
export const SpreadFragmentDoc = gql`
  fragment Spread on BookSpread {
    id
    isTemplate
    isCollaborative
    isDuplicatable
    isEditable
    hasHistory
    thumbnailURL
    author {
      email
      firstName
      lastName
    }
    elements {
      ... on BookElementText {
        id
        type
        width
        height
        rotation
        top
        left
        content
        placeholder
        color
        fontFamily
        fontSize
        fontWeight
        fontStyle
        textDecoration
        textAlign
      }
      ... on BookElementSticker {
        id
        type
        width
        height
        rotation
        top
        left
        image {
          ...ElementImage
        }
      }
      ... on BookElementContainer {
        id
        type
        width
        height
        top
        left
        rotation
        image {
          ...ElementImage
        }
        round
        imageWidth
        imageHeight
        imageTop
        imageLeft
        createdAt
      }
      ... on BookElementFrame {
        id
        type
        width
        height
        top
        left
        rotation
        mask
        image {
          ...ElementImage
        }
        frame {
          ...ElementImage
        }
        round
        imageWidth
        imageHeight
        imageTop
        imageLeft
        createdAt
      }
    }
    background {
      left {
        image {
          url
          blobID
        }
        color
      }
      right {
        image {
          url
          blobID
        }
        color
      }
    }
  }
  ${ElementImageFragmentDoc}
`;
export const AdminStickerUploadDocument = gql`
  mutation AdminStickerUpload($input: Upload!) {
    adminStickerUpload(input: $input) {
      blobID
      url
    }
  }
`;
export type AdminStickerUploadMutationFn = Apollo.MutationFunction<
  AdminStickerUploadMutation,
  AdminStickerUploadMutationVariables
>;

/**
 * __useAdminStickerUploadMutation__
 *
 * To run a mutation, you first call `useAdminStickerUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminStickerUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminStickerUploadMutation, { data, loading, error }] = useAdminStickerUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminStickerUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminStickerUploadMutation,
    AdminStickerUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminStickerUploadMutation,
    AdminStickerUploadMutationVariables
  >(AdminStickerUploadDocument, options);
}
export type AdminStickerUploadMutationHookResult = ReturnType<
  typeof useAdminStickerUploadMutation
>;
export type AdminStickerUploadMutationResult =
  Apollo.MutationResult<AdminStickerUploadMutation>;
export type AdminStickerUploadMutationOptions = Apollo.BaseMutationOptions<
  AdminStickerUploadMutation,
  AdminStickerUploadMutationVariables
>;
export const AdminStickerReorderDocument = gql`
  mutation AdminStickerReorder($sourceIndex: Int!, $destinationIndex: Int!) {
    adminStickerReorder(
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type AdminStickerReorderMutationFn = Apollo.MutationFunction<
  AdminStickerReorderMutation,
  AdminStickerReorderMutationVariables
>;

/**
 * __useAdminStickerReorderMutation__
 *
 * To run a mutation, you first call `useAdminStickerReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminStickerReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminStickerReorderMutation, { data, loading, error }] = useAdminStickerReorderMutation({
 *   variables: {
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useAdminStickerReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminStickerReorderMutation,
    AdminStickerReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminStickerReorderMutation,
    AdminStickerReorderMutationVariables
  >(AdminStickerReorderDocument, options);
}
export type AdminStickerReorderMutationHookResult = ReturnType<
  typeof useAdminStickerReorderMutation
>;
export type AdminStickerReorderMutationResult =
  Apollo.MutationResult<AdminStickerReorderMutation>;
export type AdminStickerReorderMutationOptions = Apollo.BaseMutationOptions<
  AdminStickerReorderMutation,
  AdminStickerReorderMutationVariables
>;
export const AdminFrameUploadDocument = gql`
  mutation AdminFrameUpload($input: Upload!, $mask: Mask!) {
    adminFrameUpload(input: $input, mask: $mask) {
      blobID
      url
    }
  }
`;
export type AdminFrameUploadMutationFn = Apollo.MutationFunction<
  AdminFrameUploadMutation,
  AdminFrameUploadMutationVariables
>;

/**
 * __useAdminFrameUploadMutation__
 *
 * To run a mutation, you first call `useAdminFrameUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminFrameUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminFrameUploadMutation, { data, loading, error }] = useAdminFrameUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *      mask: // value for 'mask'
 *   },
 * });
 */
export function useAdminFrameUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminFrameUploadMutation,
    AdminFrameUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminFrameUploadMutation,
    AdminFrameUploadMutationVariables
  >(AdminFrameUploadDocument, options);
}
export type AdminFrameUploadMutationHookResult = ReturnType<
  typeof useAdminFrameUploadMutation
>;
export type AdminFrameUploadMutationResult =
  Apollo.MutationResult<AdminFrameUploadMutation>;
export type AdminFrameUploadMutationOptions = Apollo.BaseMutationOptions<
  AdminFrameUploadMutation,
  AdminFrameUploadMutationVariables
>;
export const AdminFrameReorderDocument = gql`
  mutation AdminFrameReorder($sourceIndex: Int!, $destinationIndex: Int!) {
    adminFrameReorder(
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type AdminFrameReorderMutationFn = Apollo.MutationFunction<
  AdminFrameReorderMutation,
  AdminFrameReorderMutationVariables
>;

/**
 * __useAdminFrameReorderMutation__
 *
 * To run a mutation, you first call `useAdminFrameReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminFrameReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminFrameReorderMutation, { data, loading, error }] = useAdminFrameReorderMutation({
 *   variables: {
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useAdminFrameReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminFrameReorderMutation,
    AdminFrameReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminFrameReorderMutation,
    AdminFrameReorderMutationVariables
  >(AdminFrameReorderDocument, options);
}
export type AdminFrameReorderMutationHookResult = ReturnType<
  typeof useAdminFrameReorderMutation
>;
export type AdminFrameReorderMutationResult =
  Apollo.MutationResult<AdminFrameReorderMutation>;
export type AdminFrameReorderMutationOptions = Apollo.BaseMutationOptions<
  AdminFrameReorderMutation,
  AdminFrameReorderMutationVariables
>;
export const AdminBackgroundUploadDocument = gql`
  mutation AdminBackgroundUpload($input: Upload!) {
    adminBackgroundUpload(input: $input) {
      blobID
      url
    }
  }
`;
export type AdminBackgroundUploadMutationFn = Apollo.MutationFunction<
  AdminBackgroundUploadMutation,
  AdminBackgroundUploadMutationVariables
>;

/**
 * __useAdminBackgroundUploadMutation__
 *
 * To run a mutation, you first call `useAdminBackgroundUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBackgroundUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBackgroundUploadMutation, { data, loading, error }] = useAdminBackgroundUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminBackgroundUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminBackgroundUploadMutation,
    AdminBackgroundUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminBackgroundUploadMutation,
    AdminBackgroundUploadMutationVariables
  >(AdminBackgroundUploadDocument, options);
}
export type AdminBackgroundUploadMutationHookResult = ReturnType<
  typeof useAdminBackgroundUploadMutation
>;
export type AdminBackgroundUploadMutationResult =
  Apollo.MutationResult<AdminBackgroundUploadMutation>;
export type AdminBackgroundUploadMutationOptions = Apollo.BaseMutationOptions<
  AdminBackgroundUploadMutation,
  AdminBackgroundUploadMutationVariables
>;
export const AdminBackgroundReorderDocument = gql`
  mutation AdminBackgroundReorder($sourceIndex: Int!, $destinationIndex: Int!) {
    adminBackgroundReorder(
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type AdminBackgroundReorderMutationFn = Apollo.MutationFunction<
  AdminBackgroundReorderMutation,
  AdminBackgroundReorderMutationVariables
>;

/**
 * __useAdminBackgroundReorderMutation__
 *
 * To run a mutation, you first call `useAdminBackgroundReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBackgroundReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBackgroundReorderMutation, { data, loading, error }] = useAdminBackgroundReorderMutation({
 *   variables: {
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useAdminBackgroundReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminBackgroundReorderMutation,
    AdminBackgroundReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminBackgroundReorderMutation,
    AdminBackgroundReorderMutationVariables
  >(AdminBackgroundReorderDocument, options);
}
export type AdminBackgroundReorderMutationHookResult = ReturnType<
  typeof useAdminBackgroundReorderMutation
>;
export type AdminBackgroundReorderMutationResult =
  Apollo.MutationResult<AdminBackgroundReorderMutation>;
export type AdminBackgroundReorderMutationOptions = Apollo.BaseMutationOptions<
  AdminBackgroundReorderMutation,
  AdminBackgroundReorderMutationVariables
>;
export const AdminProductsReorderDocument = gql`
  mutation AdminProductsReorder($sourceIndex: Int!, $destinationIndex: Int!) {
    adminProductsReorder(
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type AdminProductsReorderMutationFn = Apollo.MutationFunction<
  AdminProductsReorderMutation,
  AdminProductsReorderMutationVariables
>;

/**
 * __useAdminProductsReorderMutation__
 *
 * To run a mutation, you first call `useAdminProductsReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminProductsReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminProductsReorderMutation, { data, loading, error }] = useAdminProductsReorderMutation({
 *   variables: {
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useAdminProductsReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminProductsReorderMutation,
    AdminProductsReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminProductsReorderMutation,
    AdminProductsReorderMutationVariables
  >(AdminProductsReorderDocument, options);
}
export type AdminProductsReorderMutationHookResult = ReturnType<
  typeof useAdminProductsReorderMutation
>;
export type AdminProductsReorderMutationResult =
  Apollo.MutationResult<AdminProductsReorderMutation>;
export type AdminProductsReorderMutationOptions = Apollo.BaseMutationOptions<
  AdminProductsReorderMutation,
  AdminProductsReorderMutationVariables
>;
export const AdminOrdersDocument = gql`
  query AdminOrders {
    adminOrders {
      id
      firstName
      lastName
      email
      createdAt
      status
      bookID
      assets {
        cover
        body
      }
    }
  }
`;

/**
 * __useAdminOrdersQuery__
 *
 * To run a query within a React component, call `useAdminOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminOrdersQuery, AdminOrdersQueryVariables>(
    AdminOrdersDocument,
    options,
  );
}
export function useAdminOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminOrdersQuery, AdminOrdersQueryVariables>(
    AdminOrdersDocument,
    options,
  );
}
export type AdminOrdersQueryHookResult = ReturnType<typeof useAdminOrdersQuery>;
export type AdminOrdersLazyQueryHookResult = ReturnType<
  typeof useAdminOrdersLazyQuery
>;
export type AdminOrdersQueryResult = Apollo.QueryResult<
  AdminOrdersQuery,
  AdminOrdersQueryVariables
>;
export const OrderAssetsGenerateDocument = gql`
  mutation OrderAssetsGenerate($orderID: UUID!) {
    orderAssetsGenerate(orderID: $orderID)
  }
`;
export type OrderAssetsGenerateMutationFn = Apollo.MutationFunction<
  OrderAssetsGenerateMutation,
  OrderAssetsGenerateMutationVariables
>;

/**
 * __useOrderAssetsGenerateMutation__
 *
 * To run a mutation, you first call `useOrderAssetsGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderAssetsGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderAssetsGenerateMutation, { data, loading, error }] = useOrderAssetsGenerateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderAssetsGenerateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderAssetsGenerateMutation,
    OrderAssetsGenerateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrderAssetsGenerateMutation,
    OrderAssetsGenerateMutationVariables
  >(OrderAssetsGenerateDocument, options);
}
export type OrderAssetsGenerateMutationHookResult = ReturnType<
  typeof useOrderAssetsGenerateMutation
>;
export type OrderAssetsGenerateMutationResult =
  Apollo.MutationResult<OrderAssetsGenerateMutation>;
export type OrderAssetsGenerateMutationOptions = Apollo.BaseMutationOptions<
  OrderAssetsGenerateMutation,
  OrderAssetsGenerateMutationVariables
>;
export const SendToPrinterDocument = gql`
  mutation SendToPrinter($orderID: UUID!) {
    sendToPrinter(orderID: $orderID)
  }
`;
export type SendToPrinterMutationFn = Apollo.MutationFunction<
  SendToPrinterMutation,
  SendToPrinterMutationVariables
>;

/**
 * __useSendToPrinterMutation__
 *
 * To run a mutation, you first call `useSendToPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToPrinterMutation, { data, loading, error }] = useSendToPrinterMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useSendToPrinterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendToPrinterMutation,
    SendToPrinterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendToPrinterMutation,
    SendToPrinterMutationVariables
  >(SendToPrinterDocument, options);
}
export type SendToPrinterMutationHookResult = ReturnType<
  typeof useSendToPrinterMutation
>;
export type SendToPrinterMutationResult =
  Apollo.MutationResult<SendToPrinterMutation>;
export type SendToPrinterMutationOptions = Apollo.BaseMutationOptions<
  SendToPrinterMutation,
  SendToPrinterMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      accessToken
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SignInDocument = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      accessToken
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options,
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignOutDocument = gql`
  mutation SignOut {
    signOut
  }
`;
export type SignOutMutationFn = Apollo.MutationFunction<
  SignOutMutation,
  SignOutMutationVariables
>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument,
    options,
  );
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const GetAccountInfoDocument = gql`
  query GetAccountInfo {
    account {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

/**
 * __useGetAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountInfoQuery,
    GetAccountInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountInfoQuery, GetAccountInfoQueryVariables>(
    GetAccountInfoDocument,
    options,
  );
}
export function useGetAccountInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountInfoQuery,
    GetAccountInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountInfoQuery, GetAccountInfoQueryVariables>(
    GetAccountInfoDocument,
    options,
  );
}
export type GetAccountInfoQueryHookResult = ReturnType<
  typeof useGetAccountInfoQuery
>;
export type GetAccountInfoLazyQueryHookResult = ReturnType<
  typeof useGetAccountInfoLazyQuery
>;
export type GetAccountInfoQueryResult = Apollo.QueryResult<
  GetAccountInfoQuery,
  GetAccountInfoQueryVariables
>;
export const PasswordForgotnDocument = gql`
  mutation PasswordForgotn($email: String!) {
    passwordForgot(email: $email)
  }
`;
export type PasswordForgotnMutationFn = Apollo.MutationFunction<
  PasswordForgotnMutation,
  PasswordForgotnMutationVariables
>;

/**
 * __usePasswordForgotnMutation__
 *
 * To run a mutation, you first call `usePasswordForgotnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordForgotnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordForgotnMutation, { data, loading, error }] = usePasswordForgotnMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordForgotnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordForgotnMutation,
    PasswordForgotnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordForgotnMutation,
    PasswordForgotnMutationVariables
  >(PasswordForgotnDocument, options);
}
export type PasswordForgotnMutationHookResult = ReturnType<
  typeof usePasswordForgotnMutation
>;
export type PasswordForgotnMutationResult =
  Apollo.MutationResult<PasswordForgotnMutation>;
export type PasswordForgotnMutationOptions = Apollo.BaseMutationOptions<
  PasswordForgotnMutation,
  PasswordForgotnMutationVariables
>;
export const PasswordResetDocument = gql`
  mutation PasswordReset($resetUrl: String!, $password: String!) {
    passwordReset(resetURL: $resetUrl, password: $password) {
      accessToken
    }
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      resetUrl: // value for 'resetUrl'
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >(PasswordResetDocument, options);
}
export type PasswordResetMutationHookResult = ReturnType<
  typeof usePasswordResetMutation
>;
export type PasswordResetMutationResult =
  Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const BookCreateDocument = gql`
  mutation BookCreate($bookID: UUID!) {
    bookCreate(bookID: $bookID)
  }
`;
export type BookCreateMutationFn = Apollo.MutationFunction<
  BookCreateMutation,
  BookCreateMutationVariables
>;

/**
 * __useBookCreateMutation__
 *
 * To run a mutation, you first call `useBookCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreateMutation, { data, loading, error }] = useBookCreateMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookCreateMutation,
    BookCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookCreateMutation, BookCreateMutationVariables>(
    BookCreateDocument,
    options,
  );
}
export type BookCreateMutationHookResult = ReturnType<
  typeof useBookCreateMutation
>;
export type BookCreateMutationResult =
  Apollo.MutationResult<BookCreateMutation>;
export type BookCreateMutationOptions = Apollo.BaseMutationOptions<
  BookCreateMutation,
  BookCreateMutationVariables
>;
export const GetPrintSpreadDocument = gql`
  query GetPrintSpread($spreadID: UUID!) {
    spread(spreadID: $spreadID) {
      ...Spread
    }
  }
  ${SpreadFragmentDoc}
`;

/**
 * __useGetPrintSpreadQuery__
 *
 * To run a query within a React component, call `useGetPrintSpreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintSpreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintSpreadQuery({
 *   variables: {
 *      spreadID: // value for 'spreadID'
 *   },
 * });
 */
export function useGetPrintSpreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrintSpreadQuery,
    GetPrintSpreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrintSpreadQuery, GetPrintSpreadQueryVariables>(
    GetPrintSpreadDocument,
    options,
  );
}
export function useGetPrintSpreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrintSpreadQuery,
    GetPrintSpreadQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrintSpreadQuery, GetPrintSpreadQueryVariables>(
    GetPrintSpreadDocument,
    options,
  );
}
export type GetPrintSpreadQueryHookResult = ReturnType<
  typeof useGetPrintSpreadQuery
>;
export type GetPrintSpreadLazyQueryHookResult = ReturnType<
  typeof useGetPrintSpreadLazyQuery
>;
export type GetPrintSpreadQueryResult = Apollo.QueryResult<
  GetPrintSpreadQuery,
  GetPrintSpreadQueryVariables
>;
export const GetPrintSpineDocument = gql`
  query GetPrintSpine($bookID: UUID!) {
    book(bookID: $bookID) {
      spine {
        ...Spine
      }
    }
  }
  ${SpineFragmentDoc}
`;

/**
 * __useGetPrintSpineQuery__
 *
 * To run a query within a React component, call `useGetPrintSpineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintSpineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintSpineQuery({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useGetPrintSpineQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPrintSpineQuery,
    GetPrintSpineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrintSpineQuery, GetPrintSpineQueryVariables>(
    GetPrintSpineDocument,
    options,
  );
}
export function useGetPrintSpineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPrintSpineQuery,
    GetPrintSpineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrintSpineQuery, GetPrintSpineQueryVariables>(
    GetPrintSpineDocument,
    options,
  );
}
export type GetPrintSpineQueryHookResult = ReturnType<
  typeof useGetPrintSpineQuery
>;
export type GetPrintSpineLazyQueryHookResult = ReturnType<
  typeof useGetPrintSpineLazyQuery
>;
export type GetPrintSpineQueryResult = Apollo.QueryResult<
  GetPrintSpineQuery,
  GetPrintSpineQueryVariables
>;
export const CalculatePriceDocument = gql`
  query CalculatePrice($bookID: UUID!, $input: BookOrderInput!) {
    calculatePrice(bookID: $bookID, input: $input) {
      size
      paper
      spreads
      cover
      gift
      delivery
      productionSpeed
      manualReviewRequested
      totalPerBook
      total
    }
  }
`;

/**
 * __useCalculatePriceQuery__
 *
 * To run a query within a React component, call `useCalculatePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculatePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculatePriceQuery({
 *   variables: {
 *      bookID: // value for 'bookID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculatePriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculatePriceQuery,
    CalculatePriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalculatePriceQuery, CalculatePriceQueryVariables>(
    CalculatePriceDocument,
    options,
  );
}
export function useCalculatePriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculatePriceQuery,
    CalculatePriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalculatePriceQuery, CalculatePriceQueryVariables>(
    CalculatePriceDocument,
    options,
  );
}
export type CalculatePriceQueryHookResult = ReturnType<
  typeof useCalculatePriceQuery
>;
export type CalculatePriceLazyQueryHookResult = ReturnType<
  typeof useCalculatePriceLazyQuery
>;
export type CalculatePriceQueryResult = Apollo.QueryResult<
  CalculatePriceQuery,
  CalculatePriceQueryVariables
>;
export const BookOrderDocument = gql`
  mutation BookOrder($bookID: UUID!, $input: BookOrderInput!) {
    bookOrder(bookID: $bookID, input: $input)
  }
`;
export type BookOrderMutationFn = Apollo.MutationFunction<
  BookOrderMutation,
  BookOrderMutationVariables
>;

/**
 * __useBookOrderMutation__
 *
 * To run a mutation, you first call `useBookOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookOrderMutation, { data, loading, error }] = useBookOrderMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookOrderMutation,
    BookOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookOrderMutation, BookOrderMutationVariables>(
    BookOrderDocument,
    options,
  );
}
export type BookOrderMutationHookResult = ReturnType<
  typeof useBookOrderMutation
>;
export type BookOrderMutationResult = Apollo.MutationResult<BookOrderMutation>;
export type BookOrderMutationOptions = Apollo.BaseMutationOptions<
  BookOrderMutation,
  BookOrderMutationVariables
>;
export const BookRemoveDocument = gql`
  mutation BookRemove($bookID: UUID!) {
    bookRemove(bookID: $bookID)
  }
`;
export type BookRemoveMutationFn = Apollo.MutationFunction<
  BookRemoveMutation,
  BookRemoveMutationVariables
>;

/**
 * __useBookRemoveMutation__
 *
 * To run a mutation, you first call `useBookRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookRemoveMutation, { data, loading, error }] = useBookRemoveMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookRemoveMutation,
    BookRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookRemoveMutation, BookRemoveMutationVariables>(
    BookRemoveDocument,
    options,
  );
}
export type BookRemoveMutationHookResult = ReturnType<
  typeof useBookRemoveMutation
>;
export type BookRemoveMutationResult =
  Apollo.MutationResult<BookRemoveMutation>;
export type BookRemoveMutationOptions = Apollo.BaseMutationOptions<
  BookRemoveMutation,
  BookRemoveMutationVariables
>;
export const BookTitleUpdateDocument = gql`
  mutation BookTitleUpdate($bookID: UUID!, $input: String!) {
    bookTitleUpdate(bookID: $bookID, input: $input) {
      id
      title
    }
  }
`;
export type BookTitleUpdateMutationFn = Apollo.MutationFunction<
  BookTitleUpdateMutation,
  BookTitleUpdateMutationVariables
>;

/**
 * __useBookTitleUpdateMutation__
 *
 * To run a mutation, you first call `useBookTitleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookTitleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookTitleUpdateMutation, { data, loading, error }] = useBookTitleUpdateMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookTitleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookTitleUpdateMutation,
    BookTitleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookTitleUpdateMutation,
    BookTitleUpdateMutationVariables
  >(BookTitleUpdateDocument, options);
}
export type BookTitleUpdateMutationHookResult = ReturnType<
  typeof useBookTitleUpdateMutation
>;
export type BookTitleUpdateMutationResult =
  Apollo.MutationResult<BookTitleUpdateMutation>;
export type BookTitleUpdateMutationOptions = Apollo.BaseMutationOptions<
  BookTitleUpdateMutation,
  BookTitleUpdateMutationVariables
>;
export const BookUpdateMemberRoleDocument = gql`
  mutation BookUpdateMemberRole($memberID: UUID!, $role: MemberRole!) {
    bookUpdateMemberRole(memberID: $memberID, role: $role)
  }
`;
export type BookUpdateMemberRoleMutationFn = Apollo.MutationFunction<
  BookUpdateMemberRoleMutation,
  BookUpdateMemberRoleMutationVariables
>;

/**
 * __useBookUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useBookUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookUpdateMemberRoleMutation, { data, loading, error }] = useBookUpdateMemberRoleMutation({
 *   variables: {
 *      memberID: // value for 'memberID'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useBookUpdateMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookUpdateMemberRoleMutation,
    BookUpdateMemberRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookUpdateMemberRoleMutation,
    BookUpdateMemberRoleMutationVariables
  >(BookUpdateMemberRoleDocument, options);
}
export type BookUpdateMemberRoleMutationHookResult = ReturnType<
  typeof useBookUpdateMemberRoleMutation
>;
export type BookUpdateMemberRoleMutationResult =
  Apollo.MutationResult<BookUpdateMemberRoleMutation>;
export type BookUpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  BookUpdateMemberRoleMutation,
  BookUpdateMemberRoleMutationVariables
>;
export const GetSharedBookDocument = gql`
  query GetSharedBook($bookId: UUID!, $shareToken: String!) {
    sharedBook(bookID: $bookId, shareToken: $shareToken) {
      id
      title
      spreads
    }
  }
`;

/**
 * __useGetSharedBookQuery__
 *
 * To run a query within a React component, call `useGetSharedBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedBookQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      shareToken: // value for 'shareToken'
 *   },
 * });
 */
export function useGetSharedBookQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSharedBookQuery,
    GetSharedBookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSharedBookQuery, GetSharedBookQueryVariables>(
    GetSharedBookDocument,
    options,
  );
}
export function useGetSharedBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSharedBookQuery,
    GetSharedBookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSharedBookQuery, GetSharedBookQueryVariables>(
    GetSharedBookDocument,
    options,
  );
}
export type GetSharedBookQueryHookResult = ReturnType<
  typeof useGetSharedBookQuery
>;
export type GetSharedBookLazyQueryHookResult = ReturnType<
  typeof useGetSharedBookLazyQuery
>;
export type GetSharedBookQueryResult = Apollo.QueryResult<
  GetSharedBookQuery,
  GetSharedBookQueryVariables
>;
export const GetShareTokenDocument = gql`
  query GetShareToken($bookId: UUID!) {
    bookShareToken(bookID: $bookId)
  }
`;

/**
 * __useGetShareTokenQuery__
 *
 * To run a query within a React component, call `useGetShareTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareTokenQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetShareTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShareTokenQuery,
    GetShareTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShareTokenQuery, GetShareTokenQueryVariables>(
    GetShareTokenDocument,
    options,
  );
}
export function useGetShareTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShareTokenQuery,
    GetShareTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShareTokenQuery, GetShareTokenQueryVariables>(
    GetShareTokenDocument,
    options,
  );
}
export type GetShareTokenQueryHookResult = ReturnType<
  typeof useGetShareTokenQuery
>;
export type GetShareTokenLazyQueryHookResult = ReturnType<
  typeof useGetShareTokenLazyQuery
>;
export type GetShareTokenQueryResult = Apollo.QueryResult<
  GetShareTokenQuery,
  GetShareTokenQueryVariables
>;
export const GenerateShareTokenDocument = gql`
  mutation GenerateShareToken($bookId: UUID!) {
    bookShareTokenGenerate(bookID: $bookId)
  }
`;
export type GenerateShareTokenMutationFn = Apollo.MutationFunction<
  GenerateShareTokenMutation,
  GenerateShareTokenMutationVariables
>;

/**
 * __useGenerateShareTokenMutation__
 *
 * To run a mutation, you first call `useGenerateShareTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShareTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShareTokenMutation, { data, loading, error }] = useGenerateShareTokenMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGenerateShareTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateShareTokenMutation,
    GenerateShareTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateShareTokenMutation,
    GenerateShareTokenMutationVariables
  >(GenerateShareTokenDocument, options);
}
export type GenerateShareTokenMutationHookResult = ReturnType<
  typeof useGenerateShareTokenMutation
>;
export type GenerateShareTokenMutationResult =
  Apollo.MutationResult<GenerateShareTokenMutation>;
export type GenerateShareTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateShareTokenMutation,
  GenerateShareTokenMutationVariables
>;
export const RemoveShareTokenDocument = gql`
  mutation RemoveShareToken($bookId: UUID!) {
    bookShareTokenRemove(bookID: $bookId)
  }
`;
export type RemoveShareTokenMutationFn = Apollo.MutationFunction<
  RemoveShareTokenMutation,
  RemoveShareTokenMutationVariables
>;

/**
 * __useRemoveShareTokenMutation__
 *
 * To run a mutation, you first call `useRemoveShareTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShareTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShareTokenMutation, { data, loading, error }] = useRemoveShareTokenMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useRemoveShareTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveShareTokenMutation,
    RemoveShareTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveShareTokenMutation,
    RemoveShareTokenMutationVariables
  >(RemoveShareTokenDocument, options);
}
export type RemoveShareTokenMutationHookResult = ReturnType<
  typeof useRemoveShareTokenMutation
>;
export type RemoveShareTokenMutationResult =
  Apollo.MutationResult<RemoveShareTokenMutation>;
export type RemoveShareTokenMutationOptions = Apollo.BaseMutationOptions<
  RemoveShareTokenMutation,
  RemoveShareTokenMutationVariables
>;
export const GetBookByIdDocument = gql`
  query GetBookById($bookId: UUID!, $invitationToken: String = "") {
    book(bookID: $bookId, invitationToken: $invitationToken) {
      id
      title
      isTemplate
      owner {
        email
        firstName
        id
      }
      spine {
        ...Spine
      }
      spreads {
        ...Spread
      }
      isDeletable
      memberRole
      memberStatus
      viewableSpreads
      modifiedAt
    }
  }
  ${SpineFragmentDoc}
  ${SpreadFragmentDoc}
`;

/**
 * __useGetBookByIdQuery__
 *
 * To run a query within a React component, call `useGetBookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookByIdQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useGetBookByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookByIdQuery,
    GetBookByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBookByIdQuery, GetBookByIdQueryVariables>(
    GetBookByIdDocument,
    options,
  );
}
export function useGetBookByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookByIdQuery,
    GetBookByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBookByIdQuery, GetBookByIdQueryVariables>(
    GetBookByIdDocument,
    options,
  );
}
export type GetBookByIdQueryHookResult = ReturnType<typeof useGetBookByIdQuery>;
export type GetBookByIdLazyQueryHookResult = ReturnType<
  typeof useGetBookByIdLazyQuery
>;
export type GetBookByIdQueryResult = Apollo.QueryResult<
  GetBookByIdQuery,
  GetBookByIdQueryVariables
>;
export const GetSpineDocument = gql`
  query GetSpine($bookId: UUID!) {
    book(bookID: $bookId) {
      id
      spine {
        ...Spine
      }
    }
  }
  ${SpineFragmentDoc}
`;

/**
 * __useGetSpineQuery__
 *
 * To run a query within a React component, call `useGetSpineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpineQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetSpineQuery(
  baseOptions: Apollo.QueryHookOptions<GetSpineQuery, GetSpineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSpineQuery, GetSpineQueryVariables>(
    GetSpineDocument,
    options,
  );
}
export function useGetSpineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpineQuery,
    GetSpineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSpineQuery, GetSpineQueryVariables>(
    GetSpineDocument,
    options,
  );
}
export type GetSpineQueryHookResult = ReturnType<typeof useGetSpineQuery>;
export type GetSpineLazyQueryHookResult = ReturnType<
  typeof useGetSpineLazyQuery
>;
export type GetSpineQueryResult = Apollo.QueryResult<
  GetSpineQuery,
  GetSpineQueryVariables
>;
export const GetSpreadByIdDocument = gql`
  query GetSpreadById($spreadId: UUID!) {
    spread(spreadID: $spreadId) {
      ...Spread
    }
  }
  ${SpreadFragmentDoc}
`;

/**
 * __useGetSpreadByIdQuery__
 *
 * To run a query within a React component, call `useGetSpreadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpreadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpreadByIdQuery({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *   },
 * });
 */
export function useGetSpreadByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSpreadByIdQuery,
    GetSpreadByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSpreadByIdQuery, GetSpreadByIdQueryVariables>(
    GetSpreadByIdDocument,
    options,
  );
}
export function useGetSpreadByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpreadByIdQuery,
    GetSpreadByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSpreadByIdQuery, GetSpreadByIdQueryVariables>(
    GetSpreadByIdDocument,
    options,
  );
}
export type GetSpreadByIdQueryHookResult = ReturnType<
  typeof useGetSpreadByIdQuery
>;
export type GetSpreadByIdLazyQueryHookResult = ReturnType<
  typeof useGetSpreadByIdLazyQuery
>;
export type GetSpreadByIdQueryResult = Apollo.QueryResult<
  GetSpreadByIdQuery,
  GetSpreadByIdQueryVariables
>;
export const GetCustomImagesDocument = gql`
  query GetCustomImages($bookId: UUID!) {
    customImages(bookID: $bookId) {
      ...BlobImage
    }
  }
  ${BlobImageFragmentDoc}
`;

/**
 * __useGetCustomImagesQuery__
 *
 * To run a query within a React component, call `useGetCustomImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomImagesQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetCustomImagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomImagesQuery,
    GetCustomImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomImagesQuery, GetCustomImagesQueryVariables>(
    GetCustomImagesDocument,
    options,
  );
}
export function useGetCustomImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomImagesQuery,
    GetCustomImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomImagesQuery,
    GetCustomImagesQueryVariables
  >(GetCustomImagesDocument, options);
}
export type GetCustomImagesQueryHookResult = ReturnType<
  typeof useGetCustomImagesQuery
>;
export type GetCustomImagesLazyQueryHookResult = ReturnType<
  typeof useGetCustomImagesLazyQuery
>;
export type GetCustomImagesQueryResult = Apollo.QueryResult<
  GetCustomImagesQuery,
  GetCustomImagesQueryVariables
>;
export const GetMediaDocument = gql`
  query GetMedia {
    stickers {
      ...BlobImage
    }
    frames {
      ...BlobImage
    }
    backgrounds {
      ...BlobImage
    }
  }
  ${BlobImageFragmentDoc}
`;

/**
 * __useGetMediaQuery__
 *
 * To run a query within a React component, call `useGetMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMediaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMediaQuery, GetMediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaQuery, GetMediaQueryVariables>(
    GetMediaDocument,
    options,
  );
}
export function useGetMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaQuery,
    GetMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaQuery, GetMediaQueryVariables>(
    GetMediaDocument,
    options,
  );
}
export type GetMediaQueryHookResult = ReturnType<typeof useGetMediaQuery>;
export type GetMediaLazyQueryHookResult = ReturnType<
  typeof useGetMediaLazyQuery
>;
export type GetMediaQueryResult = Apollo.QueryResult<
  GetMediaQuery,
  GetMediaQueryVariables
>;
export const SpineUpdateDocument = gql`
  mutation SpineUpdate(
    $bookId: UUID!
    $text: String!
    $textColor: String!
    $backgroundColor: String!
  ) {
    bookSpineUpdate(
      bookID: $bookId
      input: {
        text: $text
        textColor: $textColor
        backgroundColor: $backgroundColor
      }
    ) {
      id
      spine {
        text
        textColor
        backgroundColor
      }
    }
  }
`;
export type SpineUpdateMutationFn = Apollo.MutationFunction<
  SpineUpdateMutation,
  SpineUpdateMutationVariables
>;

/**
 * __useSpineUpdateMutation__
 *
 * To run a mutation, you first call `useSpineUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpineUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spineUpdateMutation, { data, loading, error }] = useSpineUpdateMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      text: // value for 'text'
 *      textColor: // value for 'textColor'
 *      backgroundColor: // value for 'backgroundColor'
 *   },
 * });
 */
export function useSpineUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpineUpdateMutation,
    SpineUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SpineUpdateMutation, SpineUpdateMutationVariables>(
    SpineUpdateDocument,
    options,
  );
}
export type SpineUpdateMutationHookResult = ReturnType<
  typeof useSpineUpdateMutation
>;
export type SpineUpdateMutationResult =
  Apollo.MutationResult<SpineUpdateMutation>;
export type SpineUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpineUpdateMutation,
  SpineUpdateMutationVariables
>;
export const SpreadAddDocument = gql`
  mutation SpreadAdd($bookId: UUID!) {
    bookSpreadAdd(bookID: $bookId) {
      ...Spread
    }
  }
  ${SpreadFragmentDoc}
`;
export type SpreadAddMutationFn = Apollo.MutationFunction<
  SpreadAddMutation,
  SpreadAddMutationVariables
>;

/**
 * __useSpreadAddMutation__
 *
 * To run a mutation, you first call `useSpreadAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpreadAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spreadAddMutation, { data, loading, error }] = useSpreadAddMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useSpreadAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpreadAddMutation,
    SpreadAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SpreadAddMutation, SpreadAddMutationVariables>(
    SpreadAddDocument,
    options,
  );
}
export type SpreadAddMutationHookResult = ReturnType<
  typeof useSpreadAddMutation
>;
export type SpreadAddMutationResult = Apollo.MutationResult<SpreadAddMutation>;
export type SpreadAddMutationOptions = Apollo.BaseMutationOptions<
  SpreadAddMutation,
  SpreadAddMutationVariables
>;
export const SpreadReorderDocument = gql`
  mutation SpreadReorder(
    $bookId: UUID!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    bookSpreadReorder(
      bookID: $bookId
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type SpreadReorderMutationFn = Apollo.MutationFunction<
  SpreadReorderMutation,
  SpreadReorderMutationVariables
>;

/**
 * __useSpreadReorderMutation__
 *
 * To run a mutation, you first call `useSpreadReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpreadReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spreadReorderMutation, { data, loading, error }] = useSpreadReorderMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useSpreadReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpreadReorderMutation,
    SpreadReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpreadReorderMutation,
    SpreadReorderMutationVariables
  >(SpreadReorderDocument, options);
}
export type SpreadReorderMutationHookResult = ReturnType<
  typeof useSpreadReorderMutation
>;
export type SpreadReorderMutationResult =
  Apollo.MutationResult<SpreadReorderMutation>;
export type SpreadReorderMutationOptions = Apollo.BaseMutationOptions<
  SpreadReorderMutation,
  SpreadReorderMutationVariables
>;
export const SpreadRemoveDocument = gql`
  mutation SpreadRemove($spreadId: UUID!) {
    bookSpreadRemove(spreadID: $spreadId)
  }
`;
export type SpreadRemoveMutationFn = Apollo.MutationFunction<
  SpreadRemoveMutation,
  SpreadRemoveMutationVariables
>;

/**
 * __useSpreadRemoveMutation__
 *
 * To run a mutation, you first call `useSpreadRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpreadRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spreadRemoveMutation, { data, loading, error }] = useSpreadRemoveMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *   },
 * });
 */
export function useSpreadRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpreadRemoveMutation,
    SpreadRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpreadRemoveMutation,
    SpreadRemoveMutationVariables
  >(SpreadRemoveDocument, options);
}
export type SpreadRemoveMutationHookResult = ReturnType<
  typeof useSpreadRemoveMutation
>;
export type SpreadRemoveMutationResult =
  Apollo.MutationResult<SpreadRemoveMutation>;
export type SpreadRemoveMutationOptions = Apollo.BaseMutationOptions<
  SpreadRemoveMutation,
  SpreadRemoveMutationVariables
>;
export const SpreadBackgroundUpdateDocument = gql`
  mutation SpreadBackgroundUpdate(
    $spreadId: UUID!
    $leftImageId: UUID
    $rightImageId: UUID
    $leftColor: String
    $rightColor: String
  ) {
    bookSpreadBackgroundUpdate(
      spreadID: $spreadId
      input: {
        leftImage: $leftImageId
        rightImage: $rightImageId
        leftColor: $leftColor
        rightColor: $rightColor
      }
    ) {
      __typename
    }
  }
`;
export type SpreadBackgroundUpdateMutationFn = Apollo.MutationFunction<
  SpreadBackgroundUpdateMutation,
  SpreadBackgroundUpdateMutationVariables
>;

/**
 * __useSpreadBackgroundUpdateMutation__
 *
 * To run a mutation, you first call `useSpreadBackgroundUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpreadBackgroundUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spreadBackgroundUpdateMutation, { data, loading, error }] = useSpreadBackgroundUpdateMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      leftImageId: // value for 'leftImageId'
 *      rightImageId: // value for 'rightImageId'
 *      leftColor: // value for 'leftColor'
 *      rightColor: // value for 'rightColor'
 *   },
 * });
 */
export function useSpreadBackgroundUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpreadBackgroundUpdateMutation,
    SpreadBackgroundUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SpreadBackgroundUpdateMutation,
    SpreadBackgroundUpdateMutationVariables
  >(SpreadBackgroundUpdateDocument, options);
}
export type SpreadBackgroundUpdateMutationHookResult = ReturnType<
  typeof useSpreadBackgroundUpdateMutation
>;
export type SpreadBackgroundUpdateMutationResult =
  Apollo.MutationResult<SpreadBackgroundUpdateMutation>;
export type SpreadBackgroundUpdateMutationOptions = Apollo.BaseMutationOptions<
  SpreadBackgroundUpdateMutation,
  SpreadBackgroundUpdateMutationVariables
>;
export const ImageUploadDocument = gql`
  mutation ImageUpload($bookId: UUID!, $input: Upload!) {
    customImageUpload(bookID: $bookId, input: $input) {
      blobID
      url
    }
  }
`;
export type ImageUploadMutationFn = Apollo.MutationFunction<
  ImageUploadMutation,
  ImageUploadMutationVariables
>;

/**
 * __useImageUploadMutation__
 *
 * To run a mutation, you first call `useImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadMutation, { data, loading, error }] = useImageUploadMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImageUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImageUploadMutation,
    ImageUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImageUploadMutation, ImageUploadMutationVariables>(
    ImageUploadDocument,
    options,
  );
}
export type ImageUploadMutationHookResult = ReturnType<
  typeof useImageUploadMutation
>;
export type ImageUploadMutationResult =
  Apollo.MutationResult<ImageUploadMutation>;
export type ImageUploadMutationOptions = Apollo.BaseMutationOptions<
  ImageUploadMutation,
  ImageUploadMutationVariables
>;
export const ImageRemoveDocument = gql`
  mutation ImageRemove($imageId: UUID!) {
    customImageRemove(imageID: $imageId)
  }
`;
export type ImageRemoveMutationFn = Apollo.MutationFunction<
  ImageRemoveMutation,
  ImageRemoveMutationVariables
>;

/**
 * __useImageRemoveMutation__
 *
 * To run a mutation, you first call `useImageRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageRemoveMutation, { data, loading, error }] = useImageRemoveMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useImageRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImageRemoveMutation,
    ImageRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImageRemoveMutation, ImageRemoveMutationVariables>(
    ImageRemoveDocument,
    options,
  );
}
export type ImageRemoveMutationHookResult = ReturnType<
  typeof useImageRemoveMutation
>;
export type ImageRemoveMutationResult =
  Apollo.MutationResult<ImageRemoveMutation>;
export type ImageRemoveMutationOptions = Apollo.BaseMutationOptions<
  ImageRemoveMutation,
  ImageRemoveMutationVariables
>;
export const ContainerAddDocument = gql`
  mutation ContainerAdd(
    $spreadId: UUID!
    $width: Float!
    $height: Float!
    $top: Float = 15
    $left: Float = 15
    $rotation: Float = 0
    $round: Boolean = false
    $imageId: UUID
    $imageTop: Float! = 0
    $imageLeft: Float! = 0
    $imageWidth: Float! = 100
    $imageHeight: Float! = 100
  ) {
    bookContainerAdd(
      spreadID: $spreadId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        round: $round
        imageID: $imageId
        imageTop: $imageTop
        imageLeft: $imageLeft
        imageWidth: $imageWidth
        imageHeight: $imageHeight
      }
    ) {
      id
    }
  }
`;
export type ContainerAddMutationFn = Apollo.MutationFunction<
  ContainerAddMutation,
  ContainerAddMutationVariables
>;

/**
 * __useContainerAddMutation__
 *
 * To run a mutation, you first call `useContainerAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContainerAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [containerAddMutation, { data, loading, error }] = useContainerAddMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      round: // value for 'round'
 *      imageId: // value for 'imageId'
 *      imageTop: // value for 'imageTop'
 *      imageLeft: // value for 'imageLeft'
 *      imageWidth: // value for 'imageWidth'
 *      imageHeight: // value for 'imageHeight'
 *   },
 * });
 */
export function useContainerAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContainerAddMutation,
    ContainerAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContainerAddMutation,
    ContainerAddMutationVariables
  >(ContainerAddDocument, options);
}
export type ContainerAddMutationHookResult = ReturnType<
  typeof useContainerAddMutation
>;
export type ContainerAddMutationResult =
  Apollo.MutationResult<ContainerAddMutation>;
export type ContainerAddMutationOptions = Apollo.BaseMutationOptions<
  ContainerAddMutation,
  ContainerAddMutationVariables
>;
export const FrameAddDocument = gql`
  mutation FrameAdd(
    $spreadId: UUID!
    $top: Float = 15
    $left: Float = 15
    $width: Float!
    $height: Float!
    $rotation: Float = 0
    $frameId: UUID!
    $imageId: UUID
    $imageTop: Float! = 0
    $imageLeft: Float! = 0
    $imageWidth: Float! = 100
    $imageHeight: Float! = 100
  ) {
    bookFrameAdd(
      spreadID: $spreadId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        frameID: $frameId
        imageID: $imageId
        imageTop: $imageTop
        imageLeft: $imageLeft
        imageWidth: $imageWidth
        imageHeight: $imageHeight
      }
    ) {
      id
    }
  }
`;
export type FrameAddMutationFn = Apollo.MutationFunction<
  FrameAddMutation,
  FrameAddMutationVariables
>;

/**
 * __useFrameAddMutation__
 *
 * To run a mutation, you first call `useFrameAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFrameAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [frameAddMutation, { data, loading, error }] = useFrameAddMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      rotation: // value for 'rotation'
 *      frameId: // value for 'frameId'
 *      imageId: // value for 'imageId'
 *      imageTop: // value for 'imageTop'
 *      imageLeft: // value for 'imageLeft'
 *      imageWidth: // value for 'imageWidth'
 *      imageHeight: // value for 'imageHeight'
 *   },
 * });
 */
export function useFrameAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FrameAddMutation,
    FrameAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FrameAddMutation, FrameAddMutationVariables>(
    FrameAddDocument,
    options,
  );
}
export type FrameAddMutationHookResult = ReturnType<typeof useFrameAddMutation>;
export type FrameAddMutationResult = Apollo.MutationResult<FrameAddMutation>;
export type FrameAddMutationOptions = Apollo.BaseMutationOptions<
  FrameAddMutation,
  FrameAddMutationVariables
>;
export const StickerAddDocument = gql`
  mutation StickerAdd(
    $spreadId: UUID!
    $width: Float!
    $height: Float!
    $top: Float!
    $left: Float!
    $rotation: Float = 0
    $imageId: UUID!
  ) {
    bookStickerAdd(
      spreadID: $spreadId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        imageID: $imageId
      }
    ) {
      id
    }
  }
`;
export type StickerAddMutationFn = Apollo.MutationFunction<
  StickerAddMutation,
  StickerAddMutationVariables
>;

/**
 * __useStickerAddMutation__
 *
 * To run a mutation, you first call `useStickerAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStickerAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stickerAddMutation, { data, loading, error }] = useStickerAddMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useStickerAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StickerAddMutation,
    StickerAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StickerAddMutation, StickerAddMutationVariables>(
    StickerAddDocument,
    options,
  );
}
export type StickerAddMutationHookResult = ReturnType<
  typeof useStickerAddMutation
>;
export type StickerAddMutationResult =
  Apollo.MutationResult<StickerAddMutation>;
export type StickerAddMutationOptions = Apollo.BaseMutationOptions<
  StickerAddMutation,
  StickerAddMutationVariables
>;
export const TextAddDocument = gql`
  mutation TextAdd(
    $spreadId: UUID!
    $width: Float! = 20
    $height: Float! = 20
    $top: Float = 45
    $left: Float = 15
    $rotation: Float = 0
    $content: String!
    $placeholder: String!
    $fontFamily: String = "Roboto"
    $fontSize: Float = 2.5
    $fontWeight: Int = 300
    $fontStyle: String = "normal"
    $textDecoration: String = "none"
    $textAlign: String = "left"
    $color: String = "#231F20"
  ) {
    bookTextAdd(
      spreadID: $spreadId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        content: $content
        placeholder: $placeholder
        fontFamily: $fontFamily
        fontSize: $fontSize
        fontWeight: $fontWeight
        fontStyle: $fontStyle
        textDecoration: $textDecoration
        textAlign: $textAlign
        color: $color
      }
    ) {
      id
    }
  }
`;
export type TextAddMutationFn = Apollo.MutationFunction<
  TextAddMutation,
  TextAddMutationVariables
>;

/**
 * __useTextAddMutation__
 *
 * To run a mutation, you first call `useTextAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textAddMutation, { data, loading, error }] = useTextAddMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      content: // value for 'content'
 *      placeholder: // value for 'placeholder'
 *      fontFamily: // value for 'fontFamily'
 *      fontSize: // value for 'fontSize'
 *      fontWeight: // value for 'fontWeight'
 *      fontStyle: // value for 'fontStyle'
 *      textDecoration: // value for 'textDecoration'
 *      textAlign: // value for 'textAlign'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useTextAddMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TextAddMutation,
    TextAddMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TextAddMutation, TextAddMutationVariables>(
    TextAddDocument,
    options,
  );
}
export type TextAddMutationHookResult = ReturnType<typeof useTextAddMutation>;
export type TextAddMutationResult = Apollo.MutationResult<TextAddMutation>;
export type TextAddMutationOptions = Apollo.BaseMutationOptions<
  TextAddMutation,
  TextAddMutationVariables
>;
export const ContainerUpdateDocument = gql`
  mutation ContainerUpdate(
    $elementId: UUID!
    $width: Float!
    $height: Float!
    $top: Float!
    $left: Float!
    $rotation: Float!
    $round: Boolean!
    $imageId: UUID
    $imageTop: Float!
    $imageLeft: Float!
    $imageWidth: Float!
    $imageHeight: Float!
  ) {
    bookContainerUpdate(
      elementID: $elementId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        round: $round
        imageID: $imageId
        imageTop: $imageTop
        imageLeft: $imageLeft
        imageWidth: $imageWidth
        imageHeight: $imageHeight
      }
    ) {
      id
      width
      height
      top
      left
      rotation
      round
      image {
        blobID
        url
        aspectRatio
      }
      imageTop
      imageLeft
      imageWidth
      imageHeight
    }
  }
`;
export type ContainerUpdateMutationFn = Apollo.MutationFunction<
  ContainerUpdateMutation,
  ContainerUpdateMutationVariables
>;

/**
 * __useContainerUpdateMutation__
 *
 * To run a mutation, you first call `useContainerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContainerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [containerUpdateMutation, { data, loading, error }] = useContainerUpdateMutation({
 *   variables: {
 *      elementId: // value for 'elementId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      round: // value for 'round'
 *      imageId: // value for 'imageId'
 *      imageTop: // value for 'imageTop'
 *      imageLeft: // value for 'imageLeft'
 *      imageWidth: // value for 'imageWidth'
 *      imageHeight: // value for 'imageHeight'
 *   },
 * });
 */
export function useContainerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContainerUpdateMutation,
    ContainerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContainerUpdateMutation,
    ContainerUpdateMutationVariables
  >(ContainerUpdateDocument, options);
}
export type ContainerUpdateMutationHookResult = ReturnType<
  typeof useContainerUpdateMutation
>;
export type ContainerUpdateMutationResult =
  Apollo.MutationResult<ContainerUpdateMutation>;
export type ContainerUpdateMutationOptions = Apollo.BaseMutationOptions<
  ContainerUpdateMutation,
  ContainerUpdateMutationVariables
>;
export const FrameUpdateDocument = gql`
  mutation FrameUpdate(
    $elementId: UUID!
    $top: Float!
    $left: Float!
    $width: Float!
    $height: Float!
    $rotation: Float!
    $frameId: UUID!
    $imageId: UUID
    $imageTop: Float!
    $imageLeft: Float!
    $imageWidth: Float!
    $imageHeight: Float!
  ) {
    bookFrameUpdate(
      elementID: $elementId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        frameID: $frameId
        imageID: $imageId
        imageTop: $imageTop
        imageLeft: $imageLeft
        imageWidth: $imageWidth
        imageHeight: $imageHeight
      }
    ) {
      id
      width
      height
      top
      left
      rotation
      round
      frame {
        blobID
        url
      }
      image {
        blobID
        url
        aspectRatio
      }
      imageTop
      imageLeft
      imageWidth
      imageHeight
    }
  }
`;
export type FrameUpdateMutationFn = Apollo.MutationFunction<
  FrameUpdateMutation,
  FrameUpdateMutationVariables
>;

/**
 * __useFrameUpdateMutation__
 *
 * To run a mutation, you first call `useFrameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFrameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [frameUpdateMutation, { data, loading, error }] = useFrameUpdateMutation({
 *   variables: {
 *      elementId: // value for 'elementId'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      rotation: // value for 'rotation'
 *      frameId: // value for 'frameId'
 *      imageId: // value for 'imageId'
 *      imageTop: // value for 'imageTop'
 *      imageLeft: // value for 'imageLeft'
 *      imageWidth: // value for 'imageWidth'
 *      imageHeight: // value for 'imageHeight'
 *   },
 * });
 */
export function useFrameUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FrameUpdateMutation,
    FrameUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FrameUpdateMutation, FrameUpdateMutationVariables>(
    FrameUpdateDocument,
    options,
  );
}
export type FrameUpdateMutationHookResult = ReturnType<
  typeof useFrameUpdateMutation
>;
export type FrameUpdateMutationResult =
  Apollo.MutationResult<FrameUpdateMutation>;
export type FrameUpdateMutationOptions = Apollo.BaseMutationOptions<
  FrameUpdateMutation,
  FrameUpdateMutationVariables
>;
export const StickerUpdateDocument = gql`
  mutation StickerUpdate(
    $elementId: UUID!
    $width: Float!
    $height: Float!
    $top: Float!
    $left: Float!
    $rotation: Float!
    $imageId: UUID!
  ) {
    bookStickerUpdate(
      elementID: $elementId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        imageID: $imageId
      }
    ) {
      id
      width
      height
      top
      left
      rotation
      image {
        blobID
        url
        aspectRatio
      }
    }
  }
`;
export type StickerUpdateMutationFn = Apollo.MutationFunction<
  StickerUpdateMutation,
  StickerUpdateMutationVariables
>;

/**
 * __useStickerUpdateMutation__
 *
 * To run a mutation, you first call `useStickerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStickerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stickerUpdateMutation, { data, loading, error }] = useStickerUpdateMutation({
 *   variables: {
 *      elementId: // value for 'elementId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useStickerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StickerUpdateMutation,
    StickerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StickerUpdateMutation,
    StickerUpdateMutationVariables
  >(StickerUpdateDocument, options);
}
export type StickerUpdateMutationHookResult = ReturnType<
  typeof useStickerUpdateMutation
>;
export type StickerUpdateMutationResult =
  Apollo.MutationResult<StickerUpdateMutation>;
export type StickerUpdateMutationOptions = Apollo.BaseMutationOptions<
  StickerUpdateMutation,
  StickerUpdateMutationVariables
>;
export const TextUpdateDocument = gql`
  mutation TextUpdate(
    $elementId: UUID!
    $width: Float!
    $height: Float!
    $top: Float!
    $left: Float!
    $rotation: Float!
    $content: String!
    $placeholder: String!
    $fontFamily: String!
    $fontSize: Float!
    $fontWeight: Int!
    $fontStyle: String!
    $textDecoration: String!
    $textAlign: String!
    $color: String!
  ) {
    bookTextUpdate(
      elementID: $elementId
      input: {
        width: $width
        height: $height
        top: $top
        left: $left
        rotation: $rotation
        content: $content
        placeholder: $placeholder
        fontFamily: $fontFamily
        fontSize: $fontSize
        fontWeight: $fontWeight
        fontStyle: $fontStyle
        textDecoration: $textDecoration
        textAlign: $textAlign
        color: $color
      }
    ) {
      id
      width
      height
      top
      left
      rotation
      content
      placeholder
      fontFamily
      fontSize
      fontWeight
      fontStyle
      textDecoration
      textAlign
      color
    }
  }
`;
export type TextUpdateMutationFn = Apollo.MutationFunction<
  TextUpdateMutation,
  TextUpdateMutationVariables
>;

/**
 * __useTextUpdateMutation__
 *
 * To run a mutation, you first call `useTextUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textUpdateMutation, { data, loading, error }] = useTextUpdateMutation({
 *   variables: {
 *      elementId: // value for 'elementId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      top: // value for 'top'
 *      left: // value for 'left'
 *      rotation: // value for 'rotation'
 *      content: // value for 'content'
 *      placeholder: // value for 'placeholder'
 *      fontFamily: // value for 'fontFamily'
 *      fontSize: // value for 'fontSize'
 *      fontWeight: // value for 'fontWeight'
 *      fontStyle: // value for 'fontStyle'
 *      textDecoration: // value for 'textDecoration'
 *      textAlign: // value for 'textAlign'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useTextUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TextUpdateMutation,
    TextUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TextUpdateMutation, TextUpdateMutationVariables>(
    TextUpdateDocument,
    options,
  );
}
export type TextUpdateMutationHookResult = ReturnType<
  typeof useTextUpdateMutation
>;
export type TextUpdateMutationResult =
  Apollo.MutationResult<TextUpdateMutation>;
export type TextUpdateMutationOptions = Apollo.BaseMutationOptions<
  TextUpdateMutation,
  TextUpdateMutationVariables
>;
export const ElementRemoveDocument = gql`
  mutation ElementRemove($elementId: UUID!) {
    bookElementRemove(elementID: $elementId)
  }
`;
export type ElementRemoveMutationFn = Apollo.MutationFunction<
  ElementRemoveMutation,
  ElementRemoveMutationVariables
>;

/**
 * __useElementRemoveMutation__
 *
 * To run a mutation, you first call `useElementRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useElementRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [elementRemoveMutation, { data, loading, error }] = useElementRemoveMutation({
 *   variables: {
 *      elementId: // value for 'elementId'
 *   },
 * });
 */
export function useElementRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ElementRemoveMutation,
    ElementRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ElementRemoveMutation,
    ElementRemoveMutationVariables
  >(ElementRemoveDocument, options);
}
export type ElementRemoveMutationHookResult = ReturnType<
  typeof useElementRemoveMutation
>;
export type ElementRemoveMutationResult =
  Apollo.MutationResult<ElementRemoveMutation>;
export type ElementRemoveMutationOptions = Apollo.BaseMutationOptions<
  ElementRemoveMutation,
  ElementRemoveMutationVariables
>;
export const ElementReorderDocument = gql`
  mutation ElementReorder(
    $spreadId: UUID!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    bookElementReorder(
      spreadID: $spreadId
      input: { sourceIndex: $sourceIndex, destinationIndex: $destinationIndex }
    )
  }
`;
export type ElementReorderMutationFn = Apollo.MutationFunction<
  ElementReorderMutation,
  ElementReorderMutationVariables
>;

/**
 * __useElementReorderMutation__
 *
 * To run a mutation, you first call `useElementReorderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useElementReorderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [elementReorderMutation, { data, loading, error }] = useElementReorderMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *      sourceIndex: // value for 'sourceIndex'
 *      destinationIndex: // value for 'destinationIndex'
 *   },
 * });
 */
export function useElementReorderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ElementReorderMutation,
    ElementReorderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ElementReorderMutation,
    ElementReorderMutationVariables
  >(ElementReorderDocument, options);
}
export type ElementReorderMutationHookResult = ReturnType<
  typeof useElementReorderMutation
>;
export type ElementReorderMutationResult =
  Apollo.MutationResult<ElementReorderMutation>;
export type ElementReorderMutationOptions = Apollo.BaseMutationOptions<
  ElementReorderMutation,
  ElementReorderMutationVariables
>;
export const BookSpreadToggleCollaborativeDocument = gql`
  mutation BookSpreadToggleCollaborative($spreadId: UUID!) {
    bookSpreadToggleCollaborative(spreadID: $spreadId)
  }
`;
export type BookSpreadToggleCollaborativeMutationFn = Apollo.MutationFunction<
  BookSpreadToggleCollaborativeMutation,
  BookSpreadToggleCollaborativeMutationVariables
>;

/**
 * __useBookSpreadToggleCollaborativeMutation__
 *
 * To run a mutation, you first call `useBookSpreadToggleCollaborativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSpreadToggleCollaborativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSpreadToggleCollaborativeMutation, { data, loading, error }] = useBookSpreadToggleCollaborativeMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *   },
 * });
 */
export function useBookSpreadToggleCollaborativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookSpreadToggleCollaborativeMutation,
    BookSpreadToggleCollaborativeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookSpreadToggleCollaborativeMutation,
    BookSpreadToggleCollaborativeMutationVariables
  >(BookSpreadToggleCollaborativeDocument, options);
}
export type BookSpreadToggleCollaborativeMutationHookResult = ReturnType<
  typeof useBookSpreadToggleCollaborativeMutation
>;
export type BookSpreadToggleCollaborativeMutationResult =
  Apollo.MutationResult<BookSpreadToggleCollaborativeMutation>;
export type BookSpreadToggleCollaborativeMutationOptions =
  Apollo.BaseMutationOptions<
    BookSpreadToggleCollaborativeMutation,
    BookSpreadToggleCollaborativeMutationVariables
  >;
export const BookInvitationTokenGenerateDocument = gql`
  mutation BookInvitationTokenGenerate($bookID: UUID!) {
    bookInvitationTokenGenerate(bookID: $bookID)
  }
`;
export type BookInvitationTokenGenerateMutationFn = Apollo.MutationFunction<
  BookInvitationTokenGenerateMutation,
  BookInvitationTokenGenerateMutationVariables
>;

/**
 * __useBookInvitationTokenGenerateMutation__
 *
 * To run a mutation, you first call `useBookInvitationTokenGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookInvitationTokenGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookInvitationTokenGenerateMutation, { data, loading, error }] = useBookInvitationTokenGenerateMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookInvitationTokenGenerateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookInvitationTokenGenerateMutation,
    BookInvitationTokenGenerateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookInvitationTokenGenerateMutation,
    BookInvitationTokenGenerateMutationVariables
  >(BookInvitationTokenGenerateDocument, options);
}
export type BookInvitationTokenGenerateMutationHookResult = ReturnType<
  typeof useBookInvitationTokenGenerateMutation
>;
export type BookInvitationTokenGenerateMutationResult =
  Apollo.MutationResult<BookInvitationTokenGenerateMutation>;
export type BookInvitationTokenGenerateMutationOptions =
  Apollo.BaseMutationOptions<
    BookInvitationTokenGenerateMutation,
    BookInvitationTokenGenerateMutationVariables
  >;
export const GetBookInvitationTokenDocument = gql`
  query GetBookInvitationToken($bookID: UUID!) {
    bookInvitationToken(bookID: $bookID)
  }
`;

/**
 * __useGetBookInvitationTokenQuery__
 *
 * To run a query within a React component, call `useGetBookInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookInvitationTokenQuery({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useGetBookInvitationTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookInvitationTokenQuery,
    GetBookInvitationTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBookInvitationTokenQuery,
    GetBookInvitationTokenQueryVariables
  >(GetBookInvitationTokenDocument, options);
}
export function useGetBookInvitationTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookInvitationTokenQuery,
    GetBookInvitationTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBookInvitationTokenQuery,
    GetBookInvitationTokenQueryVariables
  >(GetBookInvitationTokenDocument, options);
}
export type GetBookInvitationTokenQueryHookResult = ReturnType<
  typeof useGetBookInvitationTokenQuery
>;
export type GetBookInvitationTokenLazyQueryHookResult = ReturnType<
  typeof useGetBookInvitationTokenLazyQuery
>;
export type GetBookInvitationTokenQueryResult = Apollo.QueryResult<
  GetBookInvitationTokenQuery,
  GetBookInvitationTokenQueryVariables
>;
export const BookSpreadDuplicateDocument = gql`
  mutation BookSpreadDuplicate($spreadId: UUID!) {
    bookSpreadDuplicate(spreadID: $spreadId)
  }
`;
export type BookSpreadDuplicateMutationFn = Apollo.MutationFunction<
  BookSpreadDuplicateMutation,
  BookSpreadDuplicateMutationVariables
>;

/**
 * __useBookSpreadDuplicateMutation__
 *
 * To run a mutation, you first call `useBookSpreadDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSpreadDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSpreadDuplicateMutation, { data, loading, error }] = useBookSpreadDuplicateMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *   },
 * });
 */
export function useBookSpreadDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookSpreadDuplicateMutation,
    BookSpreadDuplicateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookSpreadDuplicateMutation,
    BookSpreadDuplicateMutationVariables
  >(BookSpreadDuplicateDocument, options);
}
export type BookSpreadDuplicateMutationHookResult = ReturnType<
  typeof useBookSpreadDuplicateMutation
>;
export type BookSpreadDuplicateMutationResult =
  Apollo.MutationResult<BookSpreadDuplicateMutation>;
export type BookSpreadDuplicateMutationOptions = Apollo.BaseMutationOptions<
  BookSpreadDuplicateMutation,
  BookSpreadDuplicateMutationVariables
>;
export const BookJoinDocument = gql`
  mutation BookJoin($invitationToken: UUID!) {
    bookJoin(invitationToken: $invitationToken) {
      id
      spreads {
        ...Spread
      }
    }
  }
  ${SpreadFragmentDoc}
`;
export type BookJoinMutationFn = Apollo.MutationFunction<
  BookJoinMutation,
  BookJoinMutationVariables
>;

/**
 * __useBookJoinMutation__
 *
 * To run a mutation, you first call `useBookJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookJoinMutation, { data, loading, error }] = useBookJoinMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useBookJoinMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookJoinMutation,
    BookJoinMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookJoinMutation, BookJoinMutationVariables>(
    BookJoinDocument,
    options,
  );
}
export type BookJoinMutationHookResult = ReturnType<typeof useBookJoinMutation>;
export type BookJoinMutationResult = Apollo.MutationResult<BookJoinMutation>;
export type BookJoinMutationOptions = Apollo.BaseMutationOptions<
  BookJoinMutation,
  BookJoinMutationVariables
>;
export const BookSpreadCreateCollaborativeDocument = gql`
  mutation BookSpreadCreateCollaborative($bookID: UUID!) {
    bookSpreadCreateCollaborative(bookID: $bookID)
  }
`;
export type BookSpreadCreateCollaborativeMutationFn = Apollo.MutationFunction<
  BookSpreadCreateCollaborativeMutation,
  BookSpreadCreateCollaborativeMutationVariables
>;

/**
 * __useBookSpreadCreateCollaborativeMutation__
 *
 * To run a mutation, you first call `useBookSpreadCreateCollaborativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookSpreadCreateCollaborativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookSpreadCreateCollaborativeMutation, { data, loading, error }] = useBookSpreadCreateCollaborativeMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookSpreadCreateCollaborativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookSpreadCreateCollaborativeMutation,
    BookSpreadCreateCollaborativeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookSpreadCreateCollaborativeMutation,
    BookSpreadCreateCollaborativeMutationVariables
  >(BookSpreadCreateCollaborativeDocument, options);
}
export type BookSpreadCreateCollaborativeMutationHookResult = ReturnType<
  typeof useBookSpreadCreateCollaborativeMutation
>;
export type BookSpreadCreateCollaborativeMutationResult =
  Apollo.MutationResult<BookSpreadCreateCollaborativeMutation>;
export type BookSpreadCreateCollaborativeMutationOptions =
  Apollo.BaseMutationOptions<
    BookSpreadCreateCollaborativeMutation,
    BookSpreadCreateCollaborativeMutationVariables
  >;
export const BookMarkAsCompleteDocument = gql`
  mutation BookMarkAsComplete($bookID: UUID!) {
    bookMarkAsComplete(bookID: $bookID)
  }
`;
export type BookMarkAsCompleteMutationFn = Apollo.MutationFunction<
  BookMarkAsCompleteMutation,
  BookMarkAsCompleteMutationVariables
>;

/**
 * __useBookMarkAsCompleteMutation__
 *
 * To run a mutation, you first call `useBookMarkAsCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookMarkAsCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookMarkAsCompleteMutation, { data, loading, error }] = useBookMarkAsCompleteMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookMarkAsCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookMarkAsCompleteMutation,
    BookMarkAsCompleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookMarkAsCompleteMutation,
    BookMarkAsCompleteMutationVariables
  >(BookMarkAsCompleteDocument, options);
}
export type BookMarkAsCompleteMutationHookResult = ReturnType<
  typeof useBookMarkAsCompleteMutation
>;
export type BookMarkAsCompleteMutationResult =
  Apollo.MutationResult<BookMarkAsCompleteMutation>;
export type BookMarkAsCompleteMutationOptions = Apollo.BaseMutationOptions<
  BookMarkAsCompleteMutation,
  BookMarkAsCompleteMutationVariables
>;
export const UndoSpreadDocument = gql`
  mutation UndoSpread($spreadId: UUID!) {
    bookSpreadUndo(spreadID: $spreadId)
  }
`;
export type UndoSpreadMutationFn = Apollo.MutationFunction<
  UndoSpreadMutation,
  UndoSpreadMutationVariables
>;

/**
 * __useUndoSpreadMutation__
 *
 * To run a mutation, you first call `useUndoSpreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoSpreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoSpreadMutation, { data, loading, error }] = useUndoSpreadMutation({
 *   variables: {
 *      spreadId: // value for 'spreadId'
 *   },
 * });
 */
export function useUndoSpreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndoSpreadMutation,
    UndoSpreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UndoSpreadMutation, UndoSpreadMutationVariables>(
    UndoSpreadDocument,
    options,
  );
}
export type UndoSpreadMutationHookResult = ReturnType<
  typeof useUndoSpreadMutation
>;
export type UndoSpreadMutationResult =
  Apollo.MutationResult<UndoSpreadMutation>;
export type UndoSpreadMutationOptions = Apollo.BaseMutationOptions<
  UndoSpreadMutation,
  UndoSpreadMutationVariables
>;
export const BookToggleViewableSpreadsDocument = gql`
  mutation BookToggleViewableSpreads($bookID: UUID!) {
    bookToggleViewableSpreads(bookID: $bookID)
  }
`;
export type BookToggleViewableSpreadsMutationFn = Apollo.MutationFunction<
  BookToggleViewableSpreadsMutation,
  BookToggleViewableSpreadsMutationVariables
>;

/**
 * __useBookToggleViewableSpreadsMutation__
 *
 * To run a mutation, you first call `useBookToggleViewableSpreadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookToggleViewableSpreadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookToggleViewableSpreadsMutation, { data, loading, error }] = useBookToggleViewableSpreadsMutation({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useBookToggleViewableSpreadsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookToggleViewableSpreadsMutation,
    BookToggleViewableSpreadsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookToggleViewableSpreadsMutation,
    BookToggleViewableSpreadsMutationVariables
  >(BookToggleViewableSpreadsDocument, options);
}
export type BookToggleViewableSpreadsMutationHookResult = ReturnType<
  typeof useBookToggleViewableSpreadsMutation
>;
export type BookToggleViewableSpreadsMutationResult =
  Apollo.MutationResult<BookToggleViewableSpreadsMutation>;
export type BookToggleViewableSpreadsMutationOptions =
  Apollo.BaseMutationOptions<
    BookToggleViewableSpreadsMutation,
    BookToggleViewableSpreadsMutationVariables
  >;
export const GetProductBySlugDocument = gql`
  query GetProductBySlug($slug: String!) {
    product(slug: $slug) {
      id
      bookID
      slug
      title
      description
      image
      introTitle
      introImage
      heroImage
      exampleBookLargeMobile
      exampleBookLarge
      exampleBookMedium
      hideCTA
      images
      seo {
        title
        description
      }
    }
  }
`;

/**
 * __useGetProductBySlugQuery__
 *
 * To run a query within a React component, call `useGetProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductBySlugQuery, GetProductBySlugQueryVariables>(
    GetProductBySlugDocument,
    options,
  );
}
export function useGetProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductBySlugQuery,
    GetProductBySlugQueryVariables
  >(GetProductBySlugDocument, options);
}
export type GetProductBySlugQueryHookResult = ReturnType<
  typeof useGetProductBySlugQuery
>;
export type GetProductBySlugLazyQueryHookResult = ReturnType<
  typeof useGetProductBySlugLazyQuery
>;
export type GetProductBySlugQueryResult = Apollo.QueryResult<
  GetProductBySlugQuery,
  GetProductBySlugQueryVariables
>;
export const GetCollaborativeSpreadsDocument = gql`
  query GetCollaborativeSpreads($bookId: UUID!) {
    book(bookID: $bookId) {
      spreads {
        id
        isCollaborative
      }
    }
  }
`;

/**
 * __useGetCollaborativeSpreadsQuery__
 *
 * To run a query within a React component, call `useGetCollaborativeSpreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborativeSpreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborativeSpreadsQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetCollaborativeSpreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollaborativeSpreadsQuery,
    GetCollaborativeSpreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollaborativeSpreadsQuery,
    GetCollaborativeSpreadsQueryVariables
  >(GetCollaborativeSpreadsDocument, options);
}
export function useGetCollaborativeSpreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollaborativeSpreadsQuery,
    GetCollaborativeSpreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollaborativeSpreadsQuery,
    GetCollaborativeSpreadsQueryVariables
  >(GetCollaborativeSpreadsDocument, options);
}
export type GetCollaborativeSpreadsQueryHookResult = ReturnType<
  typeof useGetCollaborativeSpreadsQuery
>;
export type GetCollaborativeSpreadsLazyQueryHookResult = ReturnType<
  typeof useGetCollaborativeSpreadsLazyQuery
>;
export type GetCollaborativeSpreadsQueryResult = Apollo.QueryResult<
  GetCollaborativeSpreadsQuery,
  GetCollaborativeSpreadsQueryVariables
>;
export const GetProductsFiltersDocument = gql`
  query GetProductsFilters {
    productFilterCategories {
      name
      filters {
        id
        value
      }
    }
  }
`;

/**
 * __useGetProductsFiltersQuery__
 *
 * To run a query within a React component, call `useGetProductsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsFiltersQuery,
    GetProductsFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsFiltersQuery,
    GetProductsFiltersQueryVariables
  >(GetProductsFiltersDocument, options);
}
export function useGetProductsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsFiltersQuery,
    GetProductsFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsFiltersQuery,
    GetProductsFiltersQueryVariables
  >(GetProductsFiltersDocument, options);
}
export type GetProductsFiltersQueryHookResult = ReturnType<
  typeof useGetProductsFiltersQuery
>;
export type GetProductsFiltersLazyQueryHookResult = ReturnType<
  typeof useGetProductsFiltersLazyQuery
>;
export type GetProductsFiltersQueryResult = Apollo.QueryResult<
  GetProductsFiltersQuery,
  GetProductsFiltersQueryVariables
>;
export const GetAllProductsDocument = gql`
  query GetAllProducts($filters: [UUID!]) {
    products(filters: $filters) {
      id
      bookID
      slug
      title
      image
      promoTitle
      promoColor
      promoImage
      introText
      filters {
        id
        value
        category
      }
    }
  }
`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllProductsQuery,
    GetAllProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options,
  );
}
export function useGetAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllProductsQuery,
    GetAllProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options,
  );
}
export type GetAllProductsQueryHookResult = ReturnType<
  typeof useGetAllProductsQuery
>;
export type GetAllProductsLazyQueryHookResult = ReturnType<
  typeof useGetAllProductsLazyQuery
>;
export type GetAllProductsQueryResult = Apollo.QueryResult<
  GetAllProductsQuery,
  GetAllProductsQueryVariables
>;
export const GetFeaturedDocument = gql`
  query GetFeatured {
    featuredProducts {
      id
      bookID
      slug
      title
      promoTitle
      promoColor
      promoImage
    }
  }
`;

/**
 * __useGetFeaturedQuery__
 *
 * To run a query within a React component, call `useGetFeaturedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeaturedQuery,
    GetFeaturedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeaturedQuery, GetFeaturedQueryVariables>(
    GetFeaturedDocument,
    options,
  );
}
export function useGetFeaturedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedQuery,
    GetFeaturedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeaturedQuery, GetFeaturedQueryVariables>(
    GetFeaturedDocument,
    options,
  );
}
export type GetFeaturedQueryHookResult = ReturnType<typeof useGetFeaturedQuery>;
export type GetFeaturedLazyQueryHookResult = ReturnType<
  typeof useGetFeaturedLazyQuery
>;
export type GetFeaturedQueryResult = Apollo.QueryResult<
  GetFeaturedQuery,
  GetFeaturedQueryVariables
>;
export const GetBooksPageDocument = gql`
  query GetBooksPage {
    books {
      id
      title
      modifiedAt
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetBooksPageQuery__
 *
 * To run a query within a React component, call `useGetBooksPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBooksPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBooksPageQuery,
    GetBooksPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBooksPageQuery, GetBooksPageQueryVariables>(
    GetBooksPageDocument,
    options,
  );
}
export function useGetBooksPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBooksPageQuery,
    GetBooksPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBooksPageQuery, GetBooksPageQueryVariables>(
    GetBooksPageDocument,
    options,
  );
}
export type GetBooksPageQueryHookResult = ReturnType<
  typeof useGetBooksPageQuery
>;
export type GetBooksPageLazyQueryHookResult = ReturnType<
  typeof useGetBooksPageLazyQuery
>;
export type GetBooksPageQueryResult = Apollo.QueryResult<
  GetBooksPageQuery,
  GetBooksPageQueryVariables
>;
export const GetProductsPageDocument = gql`
  query GetProductsPage {
    adminProducts {
      id
      bookID
      title
    }
  }
`;

/**
 * __useGetProductsPageQuery__
 *
 * To run a query within a React component, call `useGetProductsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsPageQuery,
    GetProductsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsPageQuery, GetProductsPageQueryVariables>(
    GetProductsPageDocument,
    options,
  );
}
export function useGetProductsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsPageQuery,
    GetProductsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsPageQuery,
    GetProductsPageQueryVariables
  >(GetProductsPageDocument, options);
}
export type GetProductsPageQueryHookResult = ReturnType<
  typeof useGetProductsPageQuery
>;
export type GetProductsPageLazyQueryHookResult = ReturnType<
  typeof useGetProductsPageLazyQuery
>;
export type GetProductsPageQueryResult = Apollo.QueryResult<
  GetProductsPageQuery,
  GetProductsPageQueryVariables
>;
export const GetAllStickersPageDocument = gql`
  query GetAllStickersPage {
    stickers {
      url
      status
      blobID
      originalName
    }
  }
`;

/**
 * __useGetAllStickersPageQuery__
 *
 * To run a query within a React component, call `useGetAllStickersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStickersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStickersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStickersPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllStickersPageQuery,
    GetAllStickersPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllStickersPageQuery,
    GetAllStickersPageQueryVariables
  >(GetAllStickersPageDocument, options);
}
export function useGetAllStickersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllStickersPageQuery,
    GetAllStickersPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllStickersPageQuery,
    GetAllStickersPageQueryVariables
  >(GetAllStickersPageDocument, options);
}
export type GetAllStickersPageQueryHookResult = ReturnType<
  typeof useGetAllStickersPageQuery
>;
export type GetAllStickersPageLazyQueryHookResult = ReturnType<
  typeof useGetAllStickersPageLazyQuery
>;
export type GetAllStickersPageQueryResult = Apollo.QueryResult<
  GetAllStickersPageQuery,
  GetAllStickersPageQueryVariables
>;
export const GetAllFramesPageDocument = gql`
  query GetAllFramesPage {
    frames {
      url
      status
      blobID
      originalName
    }
  }
`;

/**
 * __useGetAllFramesPageQuery__
 *
 * To run a query within a React component, call `useGetAllFramesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFramesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFramesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFramesPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFramesPageQuery,
    GetAllFramesPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFramesPageQuery, GetAllFramesPageQueryVariables>(
    GetAllFramesPageDocument,
    options,
  );
}
export function useGetAllFramesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFramesPageQuery,
    GetAllFramesPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllFramesPageQuery,
    GetAllFramesPageQueryVariables
  >(GetAllFramesPageDocument, options);
}
export type GetAllFramesPageQueryHookResult = ReturnType<
  typeof useGetAllFramesPageQuery
>;
export type GetAllFramesPageLazyQueryHookResult = ReturnType<
  typeof useGetAllFramesPageLazyQuery
>;
export type GetAllFramesPageQueryResult = Apollo.QueryResult<
  GetAllFramesPageQuery,
  GetAllFramesPageQueryVariables
>;
export const GetAllBackgroundsPageDocument = gql`
  query GetAllBackgroundsPage {
    backgrounds {
      url
      status
      blobID
      originalName
    }
  }
`;

/**
 * __useGetAllBackgroundsPageQuery__
 *
 * To run a query within a React component, call `useGetAllBackgroundsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBackgroundsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBackgroundsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBackgroundsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllBackgroundsPageQuery,
    GetAllBackgroundsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllBackgroundsPageQuery,
    GetAllBackgroundsPageQueryVariables
  >(GetAllBackgroundsPageDocument, options);
}
export function useGetAllBackgroundsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllBackgroundsPageQuery,
    GetAllBackgroundsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllBackgroundsPageQuery,
    GetAllBackgroundsPageQueryVariables
  >(GetAllBackgroundsPageDocument, options);
}
export type GetAllBackgroundsPageQueryHookResult = ReturnType<
  typeof useGetAllBackgroundsPageQuery
>;
export type GetAllBackgroundsPageLazyQueryHookResult = ReturnType<
  typeof useGetAllBackgroundsPageLazyQuery
>;
export type GetAllBackgroundsPageQueryResult = Apollo.QueryResult<
  GetAllBackgroundsPageQuery,
  GetAllBackgroundsPageQueryVariables
>;
export const GetAccountPageDocument = gql`
  query GetAccountPage {
    account {
      firstName
      lastName
      email
    }
    orders {
      productImage
      title
      size
      status
    }
  }
`;

/**
 * __useGetAccountPageQuery__
 *
 * To run a query within a React component, call `useGetAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountPageQuery,
    GetAccountPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(
    GetAccountPageDocument,
    options,
  );
}
export function useGetAccountPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountPageQuery,
    GetAccountPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(
    GetAccountPageDocument,
    options,
  );
}
export type GetAccountPageQueryHookResult = ReturnType<
  typeof useGetAccountPageQuery
>;
export type GetAccountPageLazyQueryHookResult = ReturnType<
  typeof useGetAccountPageLazyQuery
>;
export type GetAccountPageQueryResult = Apollo.QueryResult<
  GetAccountPageQuery,
  GetAccountPageQueryVariables
>;
export const GetAccountDetailsPageDocument = gql`
  query GetAccountDetailsPage {
    account {
      firstName
      lastName
      email
    }
  }
`;

/**
 * __useGetAccountDetailsPageQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountDetailsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountDetailsPageQuery,
    GetAccountDetailsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountDetailsPageQuery,
    GetAccountDetailsPageQueryVariables
  >(GetAccountDetailsPageDocument, options);
}
export function useGetAccountDetailsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountDetailsPageQuery,
    GetAccountDetailsPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountDetailsPageQuery,
    GetAccountDetailsPageQueryVariables
  >(GetAccountDetailsPageDocument, options);
}
export type GetAccountDetailsPageQueryHookResult = ReturnType<
  typeof useGetAccountDetailsPageQuery
>;
export type GetAccountDetailsPageLazyQueryHookResult = ReturnType<
  typeof useGetAccountDetailsPageLazyQuery
>;
export type GetAccountDetailsPageQueryResult = Apollo.QueryResult<
  GetAccountDetailsPageQuery,
  GetAccountDetailsPageQueryVariables
>;
export const GetAccountPersonalPageDocument = gql`
  query GetAccountPersonalPage {
    account {
      firstName
      lastName
      email
      acceptsMarketing
    }
  }
`;

/**
 * __useGetAccountPersonalPageQuery__
 *
 * To run a query within a React component, call `useGetAccountPersonalPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPersonalPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPersonalPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountPersonalPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountPersonalPageQuery,
    GetAccountPersonalPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountPersonalPageQuery,
    GetAccountPersonalPageQueryVariables
  >(GetAccountPersonalPageDocument, options);
}
export function useGetAccountPersonalPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountPersonalPageQuery,
    GetAccountPersonalPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountPersonalPageQuery,
    GetAccountPersonalPageQueryVariables
  >(GetAccountPersonalPageDocument, options);
}
export type GetAccountPersonalPageQueryHookResult = ReturnType<
  typeof useGetAccountPersonalPageQuery
>;
export type GetAccountPersonalPageLazyQueryHookResult = ReturnType<
  typeof useGetAccountPersonalPageLazyQuery
>;
export type GetAccountPersonalPageQueryResult = Apollo.QueryResult<
  GetAccountPersonalPageQuery,
  GetAccountPersonalPageQueryVariables
>;
export const AccountUpdateDocument = gql`
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      firstName
      lastName
      email
      acceptsMarketing
    }
  }
`;
export type AccountUpdateMutationFn = Apollo.MutationFunction<
  AccountUpdateMutation,
  AccountUpdateMutationVariables
>;

/**
 * __useAccountUpdateMutation__
 *
 * To run a mutation, you first call `useAccountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountUpdateMutation, { data, loading, error }] = useAccountUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountUpdateMutation,
    AccountUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AccountUpdateMutation,
    AccountUpdateMutationVariables
  >(AccountUpdateDocument, options);
}
export type AccountUpdateMutationHookResult = ReturnType<
  typeof useAccountUpdateMutation
>;
export type AccountUpdateMutationResult =
  Apollo.MutationResult<AccountUpdateMutation>;
export type AccountUpdateMutationOptions = Apollo.BaseMutationOptions<
  AccountUpdateMutation,
  AccountUpdateMutationVariables
>;
export const GetAccountAddressesPageDocument = gql`
  query GetAccountAddressesPage {
    account {
      firstName
      lastName
    }
  }
`;

/**
 * __useGetAccountAddressesPageQuery__
 *
 * To run a query within a React component, call `useGetAccountAddressesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountAddressesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountAddressesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountAddressesPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountAddressesPageQuery,
    GetAccountAddressesPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountAddressesPageQuery,
    GetAccountAddressesPageQueryVariables
  >(GetAccountAddressesPageDocument, options);
}
export function useGetAccountAddressesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountAddressesPageQuery,
    GetAccountAddressesPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountAddressesPageQuery,
    GetAccountAddressesPageQueryVariables
  >(GetAccountAddressesPageDocument, options);
}
export type GetAccountAddressesPageQueryHookResult = ReturnType<
  typeof useGetAccountAddressesPageQuery
>;
export type GetAccountAddressesPageLazyQueryHookResult = ReturnType<
  typeof useGetAccountAddressesPageLazyQuery
>;
export type GetAccountAddressesPageQueryResult = Apollo.QueryResult<
  GetAccountAddressesPageQuery,
  GetAccountAddressesPageQueryVariables
>;
export const GetOrdersPageDocument = gql`
  query GetOrdersPage {
    orders {
      orderNumber
      title
      id
      productImage
      status
      createdAt
      bookID
    }
  }
`;

/**
 * __useGetOrdersPageQuery__
 *
 * To run a query within a React component, call `useGetOrdersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrdersPageQuery,
    GetOrdersPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersPageQuery, GetOrdersPageQueryVariables>(
    GetOrdersPageDocument,
    options,
  );
}
export function useGetOrdersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersPageQuery,
    GetOrdersPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersPageQuery, GetOrdersPageQueryVariables>(
    GetOrdersPageDocument,
    options,
  );
}
export type GetOrdersPageQueryHookResult = ReturnType<
  typeof useGetOrdersPageQuery
>;
export type GetOrdersPageLazyQueryHookResult = ReturnType<
  typeof useGetOrdersPageLazyQuery
>;
export type GetOrdersPageQueryResult = Apollo.QueryResult<
  GetOrdersPageQuery,
  GetOrdersPageQueryVariables
>;
export const GetOrderPageDocument = gql`
  query GetOrderPage($orderID: UUID!) {
    order(orderID: $orderID) {
      paper
      pageCount
      productImage
      title
      orderNumber
      priceTotal
      shippingAddress {
        firstName
        lastName
        address
        city
        zip
        country
      }
      billingAddress {
        firstName
        lastName
        address
        city
        zip
        country
      }
      firstName
      lastName
      createdAt
      status
      size
      cover
      paper
      quantity
      priceShipping
    }
  }
`;

/**
 * __useGetOrderPageQuery__
 *
 * To run a query within a React component, call `useGetOrderPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderPageQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetOrderPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderPageQuery,
    GetOrderPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderPageQuery, GetOrderPageQueryVariables>(
    GetOrderPageDocument,
    options,
  );
}
export function useGetOrderPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderPageQuery,
    GetOrderPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderPageQuery, GetOrderPageQueryVariables>(
    GetOrderPageDocument,
    options,
  );
}
export type GetOrderPageQueryHookResult = ReturnType<
  typeof useGetOrderPageQuery
>;
export type GetOrderPageLazyQueryHookResult = ReturnType<
  typeof useGetOrderPageLazyQuery
>;
export type GetOrderPageQueryResult = Apollo.QueryResult<
  GetOrderPageQuery,
  GetOrderPageQueryVariables
>;
export const PasswordChangeDocument = gql`
  mutation PasswordChange($password: String!) {
    passwordChange(password: $password) {
      accessToken
    }
  }
`;
export type PasswordChangeMutationFn = Apollo.MutationFunction<
  PasswordChangeMutation,
  PasswordChangeMutationVariables
>;

/**
 * __usePasswordChangeMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMutation, { data, loading, error }] = usePasswordChangeMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordChangeMutation,
    PasswordChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordChangeMutation,
    PasswordChangeMutationVariables
  >(PasswordChangeDocument, options);
}
export type PasswordChangeMutationHookResult = ReturnType<
  typeof usePasswordChangeMutation
>;
export type PasswordChangeMutationResult =
  Apollo.MutationResult<PasswordChangeMutation>;
export type PasswordChangeMutationOptions = Apollo.BaseMutationOptions<
  PasswordChangeMutation,
  PasswordChangeMutationVariables
>;
export const GetBookMembersDocument = gql`
  query GetBookMembers($bookId: UUID!) {
    bookMembers(bookID: $bookId) {
      id
      firstName
      lastName
      email
      role
      status
    }
  }
`;

/**
 * __useGetBookMembersQuery__
 *
 * To run a query within a React component, call `useGetBookMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookMembersQuery({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useGetBookMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookMembersQuery,
    GetBookMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBookMembersQuery, GetBookMembersQueryVariables>(
    GetBookMembersDocument,
    options,
  );
}
export function useGetBookMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookMembersQuery,
    GetBookMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBookMembersQuery, GetBookMembersQueryVariables>(
    GetBookMembersDocument,
    options,
  );
}
export type GetBookMembersQueryHookResult = ReturnType<
  typeof useGetBookMembersQuery
>;
export type GetBookMembersLazyQueryHookResult = ReturnType<
  typeof useGetBookMembersLazyQuery
>;
export type GetBookMembersQueryResult = Apollo.QueryResult<
  GetBookMembersQuery,
  GetBookMembersQueryVariables
>;
export const OnSpreadReorderDocument = gql`
  subscription OnSpreadReorder($bookID: UUID!) {
    spreadReorder(bookID: $bookID) {
      spreadID
      sourceIndex
      destinationIndex
    }
  }
`;

/**
 * __useOnSpreadReorderSubscription__
 *
 * To run a query within a React component, call `useOnSpreadReorderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpreadReorderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpreadReorderSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpreadReorderSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpreadReorderSubscription,
    OnSpreadReorderSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpreadReorderSubscription,
    OnSpreadReorderSubscriptionVariables
  >(OnSpreadReorderDocument, options);
}
export type OnSpreadReorderSubscriptionHookResult = ReturnType<
  typeof useOnSpreadReorderSubscription
>;
export type OnSpreadReorderSubscriptionResult =
  Apollo.SubscriptionResult<OnSpreadReorderSubscription>;
export const OnSpreadRemoveDocument = gql`
  subscription OnSpreadRemove($bookID: UUID!) {
    spreadRemove(bookID: $bookID) {
      id
    }
  }
`;

/**
 * __useOnSpreadRemoveSubscription__
 *
 * To run a query within a React component, call `useOnSpreadRemoveSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpreadRemoveSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpreadRemoveSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpreadRemoveSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpreadRemoveSubscription,
    OnSpreadRemoveSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpreadRemoveSubscription,
    OnSpreadRemoveSubscriptionVariables
  >(OnSpreadRemoveDocument, options);
}
export type OnSpreadRemoveSubscriptionHookResult = ReturnType<
  typeof useOnSpreadRemoveSubscription
>;
export type OnSpreadRemoveSubscriptionResult =
  Apollo.SubscriptionResult<OnSpreadRemoveSubscription>;
export const OnElementUpdateDocument = gql`
  subscription OnElementUpdate($bookID: UUID!) {
    updateElement(bookID: $bookID) {
      id
      spreadID
    }
  }
`;

/**
 * __useOnElementUpdateSubscription__
 *
 * To run a query within a React component, call `useOnElementUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementUpdateSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnElementUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnElementUpdateSubscription,
    OnElementUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnElementUpdateSubscription,
    OnElementUpdateSubscriptionVariables
  >(OnElementUpdateDocument, options);
}
export type OnElementUpdateSubscriptionHookResult = ReturnType<
  typeof useOnElementUpdateSubscription
>;
export type OnElementUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnElementUpdateSubscription>;
export const OnSpreadAddDocument = gql`
  subscription OnSpreadAdd($bookID: UUID!) {
    spreadAdd(bookID: $bookID) {
      id
      destinationIndex
    }
  }
`;

/**
 * __useOnSpreadAddSubscription__
 *
 * To run a query within a React component, call `useOnSpreadAddSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpreadAddSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpreadAddSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpreadAddSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpreadAddSubscription,
    OnSpreadAddSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpreadAddSubscription,
    OnSpreadAddSubscriptionVariables
  >(OnSpreadAddDocument, options);
}
export type OnSpreadAddSubscriptionHookResult = ReturnType<
  typeof useOnSpreadAddSubscription
>;
export type OnSpreadAddSubscriptionResult =
  Apollo.SubscriptionResult<OnSpreadAddSubscription>;
export const OnElementAddDocument = gql`
  subscription OnElementAdd($bookID: UUID!) {
    addElement(bookID: $bookID) {
      id
      spreadID
    }
  }
`;

/**
 * __useOnElementAddSubscription__
 *
 * To run a query within a React component, call `useOnElementAddSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementAddSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementAddSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnElementAddSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnElementAddSubscription,
    OnElementAddSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnElementAddSubscription,
    OnElementAddSubscriptionVariables
  >(OnElementAddDocument, options);
}
export type OnElementAddSubscriptionHookResult = ReturnType<
  typeof useOnElementAddSubscription
>;
export type OnElementAddSubscriptionResult =
  Apollo.SubscriptionResult<OnElementAddSubscription>;
export const OnElementReorderDocument = gql`
  subscription OnElementReorder($bookID: UUID!) {
    reorderElement(bookID: $bookID) {
      spreadID
      sourceIndex
      destinationIndex
    }
  }
`;

/**
 * __useOnElementReorderSubscription__
 *
 * To run a query within a React component, call `useOnElementReorderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementReorderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementReorderSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnElementReorderSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnElementReorderSubscription,
    OnElementReorderSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnElementReorderSubscription,
    OnElementReorderSubscriptionVariables
  >(OnElementReorderDocument, options);
}
export type OnElementReorderSubscriptionHookResult = ReturnType<
  typeof useOnElementReorderSubscription
>;
export type OnElementReorderSubscriptionResult =
  Apollo.SubscriptionResult<OnElementReorderSubscription>;
export const OnElementRemoveDocument = gql`
  subscription OnElementRemove($bookID: UUID!) {
    removeElement(bookID: $bookID) {
      id
      spreadID
    }
  }
`;

/**
 * __useOnElementRemoveSubscription__
 *
 * To run a query within a React component, call `useOnElementRemoveSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementRemoveSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementRemoveSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnElementRemoveSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnElementRemoveSubscription,
    OnElementRemoveSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnElementRemoveSubscription,
    OnElementRemoveSubscriptionVariables
  >(OnElementRemoveDocument, options);
}
export type OnElementRemoveSubscriptionHookResult = ReturnType<
  typeof useOnElementRemoveSubscription
>;
export type OnElementRemoveSubscriptionResult =
  Apollo.SubscriptionResult<OnElementRemoveSubscription>;
export const OnElementUndoDocument = gql`
  subscription OnElementUndo($bookID: UUID!) {
    undoElement(bookID: $bookID) {
      id
      spreadID
    }
  }
`;

/**
 * __useOnElementUndoSubscription__
 *
 * To run a query within a React component, call `useOnElementUndoSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementUndoSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementUndoSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnElementUndoSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnElementUndoSubscription,
    OnElementUndoSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnElementUndoSubscription,
    OnElementUndoSubscriptionVariables
  >(OnElementUndoDocument, options);
}
export type OnElementUndoSubscriptionHookResult = ReturnType<
  typeof useOnElementUndoSubscription
>;
export type OnElementUndoSubscriptionResult =
  Apollo.SubscriptionResult<OnElementUndoSubscription>;
export const OnSpreadBackgroundUpdateDocument = gql`
  subscription onSpreadBackgroundUpdate($bookID: UUID!) {
    spreadBackgroundUpdate(bookID: $bookID) {
      spreadID
    }
  }
`;

/**
 * __useOnSpreadBackgroundUpdateSubscription__
 *
 * To run a query within a React component, call `useOnSpreadBackgroundUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpreadBackgroundUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpreadBackgroundUpdateSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpreadBackgroundUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpreadBackgroundUpdateSubscription,
    OnSpreadBackgroundUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpreadBackgroundUpdateSubscription,
    OnSpreadBackgroundUpdateSubscriptionVariables
  >(OnSpreadBackgroundUpdateDocument, options);
}
export type OnSpreadBackgroundUpdateSubscriptionHookResult = ReturnType<
  typeof useOnSpreadBackgroundUpdateSubscription
>;
export type OnSpreadBackgroundUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnSpreadBackgroundUpdateSubscription>;
export const OnSpreadThumbnailUpdateDocument = gql`
  subscription onSpreadThumbnailUpdate($bookID: UUID!) {
    spreadThumbnailUpdate(bookID: $bookID) {
      spreadID
      thumbnailURL
    }
  }
`;

/**
 * __useOnSpreadThumbnailUpdateSubscription__
 *
 * To run a query within a React component, call `useOnSpreadThumbnailUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpreadThumbnailUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpreadThumbnailUpdateSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpreadThumbnailUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpreadThumbnailUpdateSubscription,
    OnSpreadThumbnailUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpreadThumbnailUpdateSubscription,
    OnSpreadThumbnailUpdateSubscriptionVariables
  >(OnSpreadThumbnailUpdateDocument, options);
}
export type OnSpreadThumbnailUpdateSubscriptionHookResult = ReturnType<
  typeof useOnSpreadThumbnailUpdateSubscription
>;
export type OnSpreadThumbnailUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnSpreadThumbnailUpdateSubscription>;
export const OnSpineUpdateDocument = gql`
  subscription OnSpineUpdate($bookID: UUID!) {
    spineUpdate(bookID: $bookID) {
      bookID
    }
  }
`;

/**
 * __useOnSpineUpdateSubscription__
 *
 * To run a query within a React component, call `useOnSpineUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSpineUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSpineUpdateSubscription({
 *   variables: {
 *      bookID: // value for 'bookID'
 *   },
 * });
 */
export function useOnSpineUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSpineUpdateSubscription,
    OnSpineUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnSpineUpdateSubscription,
    OnSpineUpdateSubscriptionVariables
  >(OnSpineUpdateDocument, options);
}
export type OnSpineUpdateSubscriptionHookResult = ReturnType<
  typeof useOnSpineUpdateSubscription
>;
export type OnSpineUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnSpineUpdateSubscription>;

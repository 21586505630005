import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faBook,
  faCog,
  faCommentQuestion,
  faInfoCircle,
  faSignOut,
  faUserAlt,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import DropdownMenu from '@components/DropdownMenu';
import DropdownWrapper from '@components/DropdownWrapper';
import { brand, grey300, fontDark, lineLight } from '@constants/colors';
import { isBrowser } from '@constants/live-prep';
import { laptop, maxWidth, tablet } from '@constants/media-queries';
import { navigation } from '@constants/navigation-data';
import { admin, books, login, logout, userAccount } from '@constants/routes';
import { space } from '@constants/spaces';
import DialogContext from '@context/DialogContext';
import Avatar from '@elements/Avatar';
import Button from '@elements/Button';
import TopBar from '@elements/TopBar';
import { Role, useGetAccountInfoQuery } from '@graphql/generated/graphql';
import useClickOutside from '@hooks/useClickOutside';
import useMediaQuery from '@hooks/useMediaQuery';
import zindex from '@utils/zindex';

const Container = styled.div`
  border-bottom: 1px solid ${lineLight};
  padding: ${space * 1.5}px ${space * 2}px;
  display: flex;
  justify-content: flex-end;

  ${tablet} {
    padding: ${space * 1.5}px ${space * 3}px;
  }

  ${laptop} {
    padding-left: ${space * 5}px;
    padding-right: ${space * 4}px;
  }
`;

const StyledTopBar = styled(TopBar)`
  border-bottom: 1px solid ${lineLight};
`;

const Component = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  list-style: none;
  flex-grow: 1;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: ${space * 2}px;
  }

  ${tablet} {
    & > :not(:first-child) {
      margin-left: ${space * 3}px;
    }
  }
`;

const PageList = styled.li`
  order: 0;
  position: relative;

  ${laptop} {
    display: flex;
    order: 1;
  }

  & > button {
    margin-right: 16px;
  }
`;

const Account = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > :nth-child(2n) {
    margin: 0 8px;
  }
`;

const AccountLinks = styled.li`
  margin-left: auto;
  order: 2;
  & > ul {
    & > li {
      margin-left: 16px;
      ${laptop} {
        margin-left: ${space * 3}px;
      }
      &:first-of-type {
        a {
          &:hover {
            color: ${brand};
          }
        }
      }
    }
  }
`;

const FlexContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
`;

const SButton = styled.button`
  background: none;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  color: ${fontDark};
  font-size: 20px;
  box-shadow: none;
  background-color: ${grey300};

  :hover {
    background-color: ${lineLight};
  }
`;

const TextLink = styled.li`
  margin-bottom: ${space * 3}px;

  ${laptop} {
    margin-left: ${space * 3}px;
    margin-bottom: 0;
  }
  & > a {
    text-decoration: none;
    line-height: 32px;
    &:hover {
      color: ${brand};
    }
  }
`;

const TextLinks = styled(FlexContainer)<{ open: boolean }>`
  ${maxWidth.tablet} {
    padding: 16px ${space * 3}px;
    border-right: 1px solid ${lineLight};
    position: absolute;
    background-color: #fff;
    top: -12px; //top level padding
    left: -${space * 3}px; //top level padding
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    width: 150px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 300ms ease-out 150ms;
    z-index: ${zindex('side-menu')};
  }
`;

type Props = {
  children?: React.ReactNode;
};

const EditorHeader = ({ children }: Props) => {
  const [showNav, setShowNav] = useState(false);
  const { openDialog } = useContext(DialogContext);
  const { data } = useGetAccountInfoQuery();
  const { isTablet } = useMediaQuery();
  const wrapperRef = useRef<HTMLUListElement>(null);
  const isAdmin = data?.account.role === Role.Admin;
  const hasVisibleChilren =
    children && Array.isArray(children)
      ? children.filter((child) => typeof child !== undefined).length > 0
      : children;
  useClickOutside(
    wrapperRef,
    useCallback(() => setShowNav(false), []),
  );

  const toggleShowNav = () => {
    setShowNav(!showNav);
  };

  const accountDropdown = [
    {
      name: 'Mijn boeken',
      to: books,
      icon: faBook,
    },
    {
      name: 'Mijn account',
      href: userAccount,
      icon: faUserAlt,
    },
    {
      name: 'Helpdesk',
      onClick: isBrowser && window.Belco?.toggle,
      icon: faInfoCircle,
      show: isTablet,
    },
    {
      name: 'Admin',
      href: admin,
      icon: faCog,
      show: isAdmin,
    },
    {
      name: 'Uitloggen',
      href: logout,
      icon: faSignOut,
    },
  ];

  const accountDropdownComponent = <DropdownMenu items={accountDropdown} />;

  const renderLinks = useMemo(
    () =>
      navigation.map((link, i) => (
        <TextLink key={i}>
          <Link href={link.href}>{link.label}</Link>
        </TextLink>
      )),
    [],
  );

  const mobileMenu = (
    <>
      <PageList>
        {isTablet && (
          <Button
            set="tertiary"
            onClick={toggleShowNav}
            aria-label="Menu wisselen"
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        )}
        <TextLinks ref={wrapperRef} open={showNav}>
          {renderLinks}
        </TextLinks>
      </PageList>
      <AccountLinks>
        <FlexContainer>
          {data?.account.firstName ? (
            <DropdownWrapper dropdownChildren={accountDropdownComponent}>
              <Account>
                <span>{data?.account.firstName}</span>
                <FontAwesomeIcon icon={faChevronDown} />
              </Account>
            </DropdownWrapper>
          ) : (
            <Link href={login} passHref>
              <Account as="a">
                <FontAwesomeIcon icon={faUserCircle} />
                <span>Inloggen</span>
              </Account>
            </Link>
          )}
        </FlexContainer>
      </AccountLinks>
    </>
  );

  const desktopMenu = (
    <Component>
      {children}
      {data?.account.firstName ? (
        <DropdownWrapper dropdownChildren={accountDropdownComponent}>
          <Account>
            <span>{data?.account.firstName}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </Account>
        </DropdownWrapper>
      ) : (
        <Link href={login} passHref>
          <Account as="a">
            <FontAwesomeIcon icon={faUserCircle} />
            <span>Inloggen</span>
          </Account>
        </Link>
      )}
      <SButton onClick={() => openDialog('helpdesk')}>
        <StyledAvatar icon={faCommentQuestion} />
      </SButton>
    </Component>
  );

  return (
    <>
      <StyledTopBar>{isTablet ? mobileMenu : desktopMenu}</StyledTopBar>
      {isTablet && hasVisibleChilren && (
        <Container>
          <Component>{children}</Component>
        </Container>
      )}
    </>
  );
};

export default EditorHeader;
